import { Badge, Col, Row, Table } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import EmptyDataFilter from '../../../../components/Empty/EmptyDataFilter'
import { getClaimDetailLink } from '../../../../components/OrderDetail/TabLayout/Tickets'
import { SkeletonTable, SkeletonTableColumnsType } from '../../../../components/SkeletonTable'
import { formatDateTime } from '../../../../core/helpers/date-time'
import { formatMoneyByUnit } from '../../../../core/helpers/string'
import { ClaimInterface } from '../../../../Interface/ClaimInterface'
import ShipmentInterface from '../../../../Interface/ShipmentInterface'
interface ClaimInterfaceProps {
	items: ClaimInterface[]
	loading: boolean
	shipment?: ShipmentInterface
	currency: string
}
const Claims: React.FC<ClaimInterfaceProps> = (props) => {
	const { t } = useTranslation()
	const { items, loading, shipment, currency } = props
	const [isSkeleton, setIsSkeleton] = useState(true)
	const { code } = useParams()
	useEffect(() => {
		setTimeout(() => setIsSkeleton(false), 1000)
	}, [])

	const formatAmount = (amount: any, currency: string) => {
		let color = amount < 0 ? 'txt-color-red2' : 'txt-color-green'
		return <span className={color}>{`${amount > 0 ? '+' : ''}${formatMoneyByUnit(amount, currency)}`}</span>
	}

	const columns = [
		{
			key: 'code',
			title: <span className='fsz-14 line-h-22 txt-color-gray4'>{t('shipment.codeOfClaim')}</span> ,
			width: 136,
			dataIndex: 'code',
			render: (code: string) => <span className='robotomedium fsz-14 line-h-22 txt-color-orange2'>{`#${code}`}</span>,
		},
		{
			key: 'name',
			title:<span className='fsz-14 line-h-22 txt-color-gray4'>{t('shipment.nameOfClaim')}</span>,
			dataIndex: 'name',
			width: 168,
			render: (name: string) => <span className='robotoregular fsz-14 line-h-22 txt-color-black'>{name}</span>,
		},
		{
			key: 'timestamp',
			dataIndex: 'createdAt',
			title: <span className='fsz-14 line-h-22 txt-color-gray4'>{t('shipment.timestampOfClaim')}</span>,
			width: 176,
			render: (createdAt: string) => (
				<span className='robotoregular fsz-14 line-h-22 txt-color-black'>{formatDateTime(moment(createdAt))}</span>
			),
		},
		{
			key: 'status',
			title: <span className='fsz-14 line-h-22 txt-color-gray4'>{t('shipment.statusOfClaim')}</span>,
			width: 160,
			render: (record: any) => (
				<Badge
					color={record.publicState?.color ?? ''}
					text={`${record?.publicState?.name ?? '---'} ${record.archived ? `(${t('tickets.archived')})` : ''}`}
				/>
			),
		},
		{
			key: 'refund',
			title: <span className='fsz-14 line-h-22 txt-color-gray4'>{t('shipment.refundOfClaim')}</span>,
			width: 160,
			align: 'right' as 'right',
			render: (record: ClaimInterface) => (
				<Row justify='end' align='middle' gutter={12}>
					<Col>
						<span className={`fsz-14 line-h-22 robotoregular`}>
							{record.state === 'REFUND' ? formatAmount(record.estimatedRefundValue, currency) : '---'}
						</span>
					</Col>
					<Col>
						<Link
							to={getClaimDetailLink(
								shipment?.provider,
								shipment?.providerUsername,
								code!,
								record.code,
								`shipment`
							)}
							className={`txt-color-blue1 item-link fsz-14 line-h-22 robotoregular`}>
							{t('orderDetail.claimDetail')}
						</Link>
					</Col>
				</Row>
			),
		},
	]
	return (
		<>
			<SkeletonTable
				loading={isSkeleton || loading}
				columns={columns as SkeletonTableColumnsType[]}
				rowCount={!loading ? items?.length : 0}>
				<Table
					columns={columns}
					dataSource={items}
					className='package-list extended mg-bt-12'
					pagination={false}
					locale={{ emptyText: <EmptyDataFilter description={t('shipment.ticketsEmpty')} /> }}
				/>
			</SkeletonTable>
		</>
	)
}

export default Claims