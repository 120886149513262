import { CloseOutlined } from '@ant-design/icons'
import { Badge, Button, Card, Col, Image, Modal, Progress, Row, Table, Tooltip } from 'antd'
import lodash from 'lodash'
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavigateFunction, useNavigate, useSearchParams } from 'react-router-dom'
import { DEFAULT_PAGE_SIZE } from '../../core/config'
import { formatNumber } from '../../core/helpers/string'
import { IMarketplace } from '../../Interface/MarketplaceInterface'
import { warehouseRepository } from '../../repositories/ProductWarehouseRepository'
import ShopeeResultImg from '../../resources/images/ShopeeResult.png'
import M24ErrorUtils from '../../utils/M24ErrorUtils'
import { detectErrorFromShopee } from '../EditProductForSell/helpers'
import EmptyDataFilter from '../Empty/EmptyDataFilter'
import M24Pagination from '../Pagination/M24Pagination'

interface PendingProductListProps {
	loading: boolean
	total: number | null
	productList: any[]
	selectedItems: string[]
	setSelectItems: Dispatch<SetStateAction<string[]>>
	searchParams: any
	setSearchParams: any
	onClickDelete: (record?: any | null) => void
	marketplaces: IMarketplace[]
}

const ActionIcon = (props: { classes: string }) => {
	const [isHovered, setHover] = useState<boolean>(false)

	return (
		<i
			className={`${isHovered ? 'fa-solid' : 'fa-light'} ${props.classes}`}
			onMouseOver={() => setHover(true)}
			onMouseLeave={() => setHover(false)}></i>
	)
}

function PendingProductList(props: PendingProductListProps) {
	const { t } = useTranslation()
	const navigator: NavigateFunction = useNavigate()
	const [searchParams, setSearchParams] = useSearchParams()
	const [productList, setProductList] = useState<any[]>([])
	const [hoveredButtonAtRowKey, setHoverButtonAtRowKey] = useState<null | string>(null)
	const [loadingUpShoppe, setLoadingUpShoppe] = useState(false)
	const [openModalProgress, setOpenModalProgress] = useState(false)
	const [percent, setPercent] = useState(0)
	const [curProduct, setCurProduct] = useState('')
	const [error, setError] = useState<null | string>(null)
	const [statusProgress, setStatusProgress] = useState<'normal' | 'active' | 'exception' | 'success'>()
	const [success, setSuccess] = useState(false)
	const [timeStart, setTimeStart] = useState(0)
	const [timeEnd, setTimeEnd] = useState(0)
	const [timer, setTimer] = useState(0)

	useEffect(() => {
		setProductList(
			props.productList.map((item) => ({
				...item,
				startSkuIndex: 0,
				endSkuIndex: 3,
			}))
		)
	}, [props.productList])

	const onChangePage = (filter: any) => {
		props.setSearchParams({
			...Object.fromEntries(props.searchParams),
			page: filter.page,
			pageSize: filter.pageSize,
		})
	}

	const onClickRow = (record: any, rowIndex: number | undefined) => {
		if (Number.isInteger(rowIndex) && hoveredButtonAtRowKey !== `${record.id}`) {
			props.setSelectItems((prevSelectedItems: string[]) => {
				const selectedRowIndex = prevSelectedItems.indexOf(`${record.id}`)
				const clonedSelectedItems = [...prevSelectedItems]
				selectedRowIndex >= 0
					? clonedSelectedItems.splice(selectedRowIndex, 1)
					: clonedSelectedItems.push(`${record.id}`)
				return clonedSelectedItems
			})
		}
	}

	const onShowMoreSkus = (id: string, skusLength: number, type: string) => {
		setProductList((prevProductList) => {
			const matchProductIndex = prevProductList.map((product) => product.id).indexOf(id)
			if (matchProductIndex >= 0) {
				const clonedProductList = [...prevProductList]
				if (type === 'show-more') {
					clonedProductList[matchProductIndex].endSkuIndex += skusLength
				} else if (type === 'show-less') {
					clonedProductList[matchProductIndex].endSkuIndex = 3
				}
				return clonedProductList
			}
			return prevProductList
		})
	}

	const localeCustom = {
		emptyText: (
			<EmptyDataFilter
				description={
					!searchParams.get('name') ? t('pendingProduct.empty-list') : t('pendingProducts.filterEmpty')
				}
			/>
		),
	}

	const navigateToEditPage = (productId: string) => {
		navigator(`/pending-products/shopee/${productId}/edit`)
	}

	const uploadShopee = useCallback(
		async (id: string) => {
			setLoadingUpShoppe(true)
			try {
				const res = await warehouseRepository.upProductToShopeeMarketplace([id])
				if (res[0].error) {
					setError(detectErrorFromShopee(res[0].msg))
				} else {
					setSuccess(true)
				}
				setLoadingUpShoppe(false)
			} catch (error) {
				M24ErrorUtils.showError(t, error)
				let title = lodash.get(error, 'response.data.title')
				setError(t(`message.${title}`))
				setLoadingUpShoppe(false)
			}
		},
		[t]
	)
	useEffect(() => {
		if (openModalProgress) {
			let timeStart = performance.now()
			uploadShopee(curProduct)
			let timeEnd = performance.now()
			setTimer(timeEnd - timeStart)
		}
	}, [uploadShopee, curProduct, openModalProgress])

	useEffect(() => {
		if (loadingUpShoppe) {
			let counter = 1
			const timerInterval = timer / 100
			const interval = setInterval(() => {
				counter = counter + 1
				setPercent(counter)
				if (counter >= 100) {
					clearInterval(interval)
				}
			}, timerInterval)
		}
	}, [loadingUpShoppe, timer])

	const columns: any = [
		{
			title: (
				<span className='fsz-14 line-h-22 robotomedium txt-color-gray4'>{t('orderDetail.productName')}</span>
			),
			dataIndex: 'name',
			key: 'name',
			width: '25%',
			render: (_: string, record: any) => (
				<Row gutter={[8, 0]} wrap={false}>
					<Col className={'dpl-flex flex-col align-self-center'}>
						<Image
							src={record.productImages[0]}
							preview={false}
							width={64}
							height={64}
							className='border-radius4 bd-none'
						/>
					</Col>
					<Row gutter={[0, 3]}>
						<Col xs={20} className={'pdl5'}>
							<span className='txt-size-h7 robotomedium line-h-22 txt-color-black'>{record.name}</span>
						</Col>
						<Col xs={24} className={'pdl5'}>
							<span className='shop-name txt-size-h8 robotoregular txt-color-blue6 dpl-il-block px-10 py-1 border-radius4 line-h-20 bd-color-blue6 bd1px'>
								{record.integrationName}
							</span>
						</Col>
					</Row>
				</Row>
			),
		},
		{
			title: <span className='fsz-14 line-h-22 robotomedium txt-color-gray4'>Marketplace</span>,
			dataIndex: 'marketplace',
			key: 'marketplace',
			width: '10%',
			render: (_: string, record: any) => {
				let marketplace = lodash.find(props.marketplaces, (m) => m.code === record.marketplace)
				return (
					<Row gutter={[3, 0]} wrap={false} className='marketplace-row'>
						<Col>
							<Image src={lodash.get(marketplace, 'icon', '')} preview={false} width={16} height={16} />
						</Col>
						<Col>
							<span className='txt-size-h7 robotoregular line-h-22 txt-color-black txt-capitalize '>
								{record.marketplace}
							</span>
						</Col>
					</Row>
				)
			},
		},
		{
			title: (
				<span className='fsz-14 line-h-22 robotomedium txt-color-gray4'>{t('warehouse.skuProductTitle')}</span>
			),
			dataIndex: '',
			width: '10%',
			render: (_: string, record: any) => (
				<span className='product-code txt-size-h7 robotoregular line-h-22 txt-color-black'>
					{record.code ? record.code : '----'}
				</span>
			),
		},
		{
			title: (
				<Row align='middle'>
					<Col xs={11}>
						<span className='fsz-14 line-h-22 robotomedium txt-color-gray4'>
							{t('warehouse.skuProductSplitTitle')}
						</span>
					</Col>
					<Col xs={8} className={'txt-right'}>
						<span className={'pdr5 fsz-14 line-h-22 robotomedium txt-color-gray4'}>
							{t('warehouse.salePrice')}
						</span>
					</Col>
					<Col xs={5} className={'txt-right'}>
						<span className={'pdr5 fsz-14 line-h-22 robotomedium txt-color-gray4'}>
							{t('warehouse.stock')}
						</span>
					</Col>
				</Row>
			),
			// dataIndex: <span className='txt-color-gray4 fsz-14 line-h-22 robotomedium'>{t('warehouse.skuProductTitle')}</span>,
			width: '40%',
			render: (_: string, record: any) => (
				<Row gutter={[0, 2]}>
					{record.skus &&
						record.skus.slice(record.startSkuIndex, record.endSkuIndex).map((sku: any) => (
							<Col xs={24} key={`${sku.oid}`}>
								<Row align='middle' className='px-12 py-8 bg-color-gray-badge'>
									<Col xs={11}>
										<span className='txt-size-h7 line-h-22 txt-color-black roboteregular '>
											{sku.name}
										</span>
									</Col>
									<Col xs={8} className={'txt-right'}>
										<span className='txt-size-h7 line-h-22 txt-color-black robotomedium align-self-center'>
											{formatNumber(sku.salePrice)}
										</span>
									</Col>
									<Col xs={5} className={'txt-right'}>
										<span className='txt-size-h7 line-h-22 txt-color-orange2 robotomedium'>
											{formatNumber(sku.stock)}
										</span>
									</Col>
								</Row>
							</Col>
						))}
					{record.endSkuIndex < record.skus?.length && (
						<Col xs={24}>
							<Row justify='center' className={'mgt5'}>
								<span
									className='btn-show-more txt-size-h8 robotomedium txt-color-blue7'
									onMouseOver={() => setHoverButtonAtRowKey(`${record.id}`)}
									onMouseLeave={() => setHoverButtonAtRowKey(null)}
									onClick={() => {
										onShowMoreSkus(record.id, record.skus.length, 'show-more')
									}}>
									<i className='fa-regular fa-plus mgl5 mgr5' />
									{t('pendingProduct.display-more-sku')}
								</span>
							</Row>
						</Col>
					)}
					{record.endSkuIndex > record.skus?.length && record.skus?.length > 2 && (
						<Col xs={24}>
							<Row justify='center' className={'mgt5'}>
								<span
									className='btn-show-more txt-size-h8 robotomedium txt-color-blue7'
									onMouseOver={() => setHoverButtonAtRowKey(`${record.id}`)}
									onMouseLeave={() => setHoverButtonAtRowKey(null)}
									onClick={() => onShowMoreSkus(record.id, record.skus.length, 'show-less')}>
									<i className='fa-regular fa-hyphen mgl5 mgr5' />
									{t('pendingProduct.display-less-sku')}
								</span>
							</Row>
						</Col>
					)}
				</Row>
			),
		},
		{
			title: (
				<div className={'width100pc txt-center fsz-14 line-h-22 robotomedium txt-color-gray4'}>
					{t('connectMarket.action')}
				</div>
			),
			dataIndex: 'action',
			key: 'action',
			width: '10%',
			render: (_: string, record: any) => (
				<Row
					gutter={12}
					justify={'center'}
					className='action-row'
					onMouseOver={() => setHoverButtonAtRowKey(`${record.id}`)}
					onMouseLeave={() => setHoverButtonAtRowKey(null)}>
					<Col>
						<Tooltip
							placement='top'
							title={t('address.edit-action')}
							color='#3340B6'
							overlayClassName='action-tooltip'
							overlayInnerStyle={{
								borderRadius: '3px',
							}}>
							<Button
								type='text'
								icon={<ActionIcon classes='fa-sliders txt-size-h8 txt-color-blue6' />}
								onClick={() => navigateToEditPage(record.id)}></Button>
						</Tooltip>
					</Col>
					{/* <Col>
						<Tooltip
							placement='top'
							title={t('warehouse.saleChoice')}
							color='#3340B6'
							overlayClassName='action-tooltip'
							overlayInnerStyle={{
								borderRadius: '3px',
							}}>
							<Button
								type='text'
								icon={<ActionIcon classes='fa-cart-plus txt-size-h8 txt-color-blue6' />}
								onClick={() => {
									setOpenModalProgress(true)
									setCurProduct(record.id)
								}}
							/>
						</Tooltip>
					</Col> */}
					<Col>
						<Tooltip
							placement='top'
							title={t('address.delete-action')}
							color='#FF4559'
							overlayClassName='action-tooltip'
							overlayInnerStyle={{
								borderRadius: '3px',
							}}>
							<Button
								type='text'
								icon={<ActionIcon classes='fa-trash txt-size-h8 txt-color-red2' />}
								onClick={() => {
									props.onClickDelete(record)
								}}></Button>
						</Tooltip>
					</Col>
				</Row>
			),
		},
	]

	return (
		<Card
			title={
				<Row align='middle' justify='space-between'>
					<Col>
						<Row align='middle' gutter={[3, 0]}>
							<Col>
								<h3 className='mgbt0'>{t('warehouse.waitingProductList')}</h3>
							</Col>
							<Col>
								<Badge
									overflowCount={9999}
									showZero
									count={props.total || 0}
									className={`${!props.total ? 'badge-zero' : 'badge-not-zero'}`}
								/>
							</Col>
						</Row>
					</Col>
					<Col>
						<Row gutter={8}>
							{/* <Col>
							    {props.selectedItems && props.selectedItems.length > 0 &&
							    <Button
							        className="btn-df-secondary-bg-blue btn-sale txt-size-h7 robotomedium border-radius4 txt-color-white"
							        icon={<i className="fa-regular fa-cart-plus"/>}
									onClick={async() => {
										try{
											await warehouseRepository.upShopee()
										}catch(err) {

										}
									}}
							    >
							        {t('warehouse.saleChoice')}
							    </Button>
							    }
							</Col> */}
							{props.selectedItems && props.selectedItems.length > 0 && (
								<Col>
									<Button
										icon={<i className='fa-solid fa-trash' />}
										className='btn-remove-product txt-size-h7 border-radius4 robotomedium txt-color-white'
										onClick={() => props.onClickDelete(null)}>
										{t('address.delete-action')}
									</Button>
								</Col>
							)}
						</Row>
					</Col>
				</Row>
			}
			className='product-list-container'>
			<Row gutter={[0, 12]}>
				<Col xs={24}>
					<Table
						className={'pendingProducts-data'}
						rowSelection={{
							selectedRowKeys: props.selectedItems,
							onChange: (selectedRowKeys: any) => {
								props.setSelectItems([...selectedRowKeys])
							},
						}}
						onRow={(record, rowIndex: number | undefined) => {
							return {
								onClick: () => onClickRow(record, rowIndex),
							}
						}}
						loading={props.loading}
						locale={localeCustom}
						columns={columns}
						dataSource={productList.map((item) => ({ ...item, key: `${item.id}` }))}
						pagination={false}
					/>
				</Col>
				{productList.map((item) => ({ ...item, key: `${item.id}` })).length > 0 && (
					<Col xs={24}>
						<Row justify='end'>
							<M24Pagination
								filter={{
									size: parseInt(props.searchParams.get('pageSize') || `${DEFAULT_PAGE_SIZE}`),
									page: parseInt(props.searchParams.get('page') || '1'),
								}}
								pagination={{
									total: props.total,
									current: parseInt(props.searchParams.get('page') || '1'),
									pageSize: parseInt(props.searchParams.get('pageSize') || `${DEFAULT_PAGE_SIZE}`),
									hideOnSinglePage: true,
								}}
								onPageChange={onChangePage}
							/>
						</Row>
					</Col>
				)}
				{console.log('statusProgress', statusProgress)}
			</Row>
			<Modal
				title='Đăng bán sản phẩm lên Shopee'
				centered
				open={openModalProgress}
				width={766}
				onCancel={() => {
					setOpenModalProgress(false)
				}}
				closable={!loadingUpShoppe && percent === 100}
				keyboard={!loadingUpShoppe && percent === 100}
				footer={[
					<Button
						icon={<CloseOutlined />}
						disabled={loadingUpShoppe || percent !== 100}
						key='back'
						onClick={() => setOpenModalProgress(false)}
						className={'btn-df btn-df-tertiary-bg-white py-4 px-15 robotoregular fsz-14 line-h-22'}>
						{t('button.exit')}
					</Button>,
				]}>
				<div className="flex">
				 <Progress
					percent={percent}
					strokeWidth={18}
					// steps={100}
					strokeColor='#3340B6'
					size='small'
					// trailColor={'#6874E2'}
					// status={percent === 100 && error ? 'exception' : success ? 'success' : 'normal'}
					className='mg-bt-12 up-shop-progress mg-r-8'

				/>
				
				</div>
				{ percent === 100 && (
					<Card title={<Image src={ShopeeResultImg} preview={false}  />} className='up-market-result' size='small'>
						{error && <div className='flex align-items-center'>
							<span className='robotomedium fsz-14 line-h-22 txt-color-black mg-r-4'>Thất Bại:</span>
							<span className='robotoregular fsz-14 line-h-22 txt-color-red2'>{error}</span>
						</div>}
						{success && <span className='obotoregular fsz-14 line-h-22 txt-color-green'>{t('shopeeMessage.successUpShopee')}</span>}
					</Card>
				)}
			
			</Modal>
		</Card>
	)
}

export default PendingProductList

