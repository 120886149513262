import { FormInstance } from 'antd';
import { UploadFile } from 'antd/es/upload/interface';
import {
  AttributeParams,
  BodyEditProductForSellInterface,
  EditProductForSellFormNames,
  LogisticParams,
  ProductForSellInterface,
} from '../../Interface/ProductInterface';
import { SKUInterface } from '../../Interface/StoredProductInterface';
import i18n from '../../resources/localizations/i18n';

export const getBodyEditProduct = (
  productImages: UploadFile<unknown>[],
  values: Record<EditProductForSellFormNames, any>
): BodyEditProductForSellInterface => {
  const images: string[] = productImages.map(
    (imgObj: UploadFile<unknown>) => imgObj.url || ''
  );
  let minMsrp: any = null;
  let minSalePrice: any = null;

  values.skus?.forEach((sku: SKUInterface | any) => {
    if (sku.image?.file) {
      sku.image = '';
    }

    if (minMsrp === null) {
      minMsrp = sku.msrp;
    }

    if (minSalePrice === null) {
      minSalePrice = sku.salePrice;
    }

    if (sku.msrp !== null && sku.msrp < minMsrp) {
      minMsrp = sku.msrp;
    }

    if (sku.salePrice !== null && sku.salePrice < minSalePrice) {
      minSalePrice = sku.salePrice;
    }
  });

  const attributes: AttributeParams[] = Object.keys(values.attributes).map(
    (key: string): AttributeParams => ({
      attributeId: Number(key),
      value: values.attributes[key] || '',
    })
  );

  const logistics: LogisticParams[] = Object.keys(values.logistics).map(
    (key: string): LogisticParams => ({
      logisticId: Number(key),
      enabled: !!values.logistics[key],
      shippingFee: 0,
    })
  );

  if (!values.isPreOrder) {
    values.daysToShip = null;
  }

  return {
    productImages: images,
    name: values.name?.trim() || '',
    categoryId: values.category[values.category.length - 1],
    code: values.code,
    skus: values.skus,
    attributes,
    logistics,
    width: values.width,
    height: values.height,
    length: values.length,
    weight: values.weight,
    msrp: minMsrp,
    salePrice: minSalePrice,
    featuredImage: images[0] || '',
    isPreOrder: values.isPreOrder,
    daysToShip: values.daysToShip,
    condition: values.condition,
  };
};

export const scrollWithOffset = (el: HTMLElement) => {
  const navCommon = document.querySelectorAll('.left-nav-bar a');
  navCommon.forEach((nav) => {
    if (nav.getAttribute('href')?.includes(el.id)) {
      nav.classList.add('active');
    } else {
      nav.classList.remove('active');
    }
  });

  window.scrollTo({ top: el.offsetTop, behavior: 'smooth' });
};

export const uploadNavActiveClass = (
  scrollTop: number,
  common: HTMLElement,
  sell: HTMLElement,
  details: HTMLElement,
  transport: HTMLElement,
  other: HTMLElement
) => {
  const navCommon = document.querySelectorAll('.left-nav-bar a');
  if (!navCommon[0]) return;

  const positionCheckView: number = scrollTop + (window.innerHeight / 3) * 2;
  if (navCommon.length === 3) {
    if (positionCheckView < sell?.offsetTop || scrollTop === 0) {
      navCommon[0].classList.add('active');
      navCommon[1].classList.remove('active');
      navCommon[2].classList.remove('active');
    } else if (positionCheckView > sell?.offsetTop) {
      navCommon[0].classList.remove('active');
      navCommon[1].classList.add('active');
      navCommon[2].classList.remove('active');
    }
    if (
      scrollTop + 10 >
      document.documentElement.scrollHeight -
        document.documentElement.clientHeight
    ) {
      navCommon[0].classList.remove('active');
      navCommon[1].classList.remove('active');
      navCommon[2].classList.add('active');
    }
    return;
  }

  if (positionCheckView < sell?.offsetTop || scrollTop === 0) {
    navCommon[0].classList.add('active');
    navCommon[1].classList.remove('active');
    navCommon[2].classList.remove('active');
    navCommon[3].classList.remove('active');
    navCommon[4].classList.remove('active');
  } else if (positionCheckView > sell?.offsetTop) {
    navCommon[0].classList.remove('active');
    navCommon[1].classList.add('active');
    navCommon[2].classList.remove('active');
    navCommon[3].classList.remove('active');
    navCommon[4].classList.remove('active');
  }
  if (positionCheckView > details?.offsetTop) {
    navCommon[0].classList.remove('active');
    navCommon[1].classList.remove('active');
    navCommon[2].classList.add('active');
    navCommon[3].classList.remove('active');
    navCommon[4].classList.remove('active');
  }
  if (positionCheckView > transport.offsetTop) {
    navCommon[0].classList.remove('active');
    navCommon[1].classList.remove('active');
    navCommon[2].classList.remove('active');
    navCommon[3].classList.add('active');
    navCommon[4].classList.remove('active');
  }
  if (
    scrollTop + 10 >
    document.documentElement.scrollHeight -
      document.documentElement.clientHeight
  ) {
    navCommon[0].classList.remove('active');
    navCommon[1].classList.remove('active');
    navCommon[2].classList.remove('active');
    navCommon[3].classList.remove('active');
    navCommon[4].classList.add('active');
  }
};

export const onPressOnlyNumber = (event: any) => {
  if (!/[0-9]/.test(event.key)) {
    event.preventDefault();
  }
};

export const onPressOnlyNumberAndAllowDecimals = (event: any) => {
  if (!/[0-9.]/.test(event.key)) {
    event.preventDefault();
  }
};

export const generateVideoThumbnailFromUrl = (url: string) => {
  return new Promise((resolve) => {
    const canvas = document.createElement('canvas');
    const video = document.createElement('video');

    // this is important
    video.autoplay = true;
    video.muted = true;
    video.src = url;

    video.onloadeddata = () => {
      let ctx: any = canvas.getContext('2d');

      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
      video.pause();
      return resolve(canvas.toDataURL('image/png'));
    };
  });
};

export const generateVideoThumbnail = (file: File) => {
  return new Promise((resolve) => {
    const canvas = document.createElement('canvas');
    const video = document.createElement('video');

    // this is important
    video.autoplay = true;
    video.muted = true;
    video.src = URL.createObjectURL(file);

    video.onloadeddata = () => {
      let ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');

      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      ctx?.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
      video.pause();
      return resolve(canvas.toDataURL('image/png'));
    };
  });
};

export const searchTree = (nodeId: any, allNode: any[]) => {
  let result: any = [];
  allNode.forEach((parent: any, index: number) => {
    const stack: any = [[parent, []]];

    while (stack.length) {
      const [node, path] = stack.pop();

      if (node.categoryId === nodeId) {
        result = path;
      }

      if (node.childrens.length > 0) {
        if (node.level === 0) {
          path.push(node.categoryId);
        }

        stack.push(
          ...node.childrens.map((node: any, i: number) => [
            node,
            [...path, node.categoryId],
          ])
        );
      }
    }
  });

  return result;
};

export const initialForm = (
  form: FormInstance<Record<EditProductForSellFormNames, any>>,
  data: ProductForSellInterface
) => {
  const logistics: any = {};
  data.logistics?.forEach((logistic: LogisticParams) => {
    logistics[String(logistic.logisticId)] = !!logistic.enabled;
  });

  const attributes: any = data.attributes;

  data.attributes?.forEach((attribute: AttributeParams) => {
    attributes[String(attribute.attributeId)] = attribute.value || undefined;
  });

  form.setFieldsValue({
    [EditProductForSellFormNames.code]: data.code,
    [EditProductForSellFormNames.name]: data.name,
    [EditProductForSellFormNames.integrationName]: data.integrationName,
    [EditProductForSellFormNames.categoryId]: data.categoryId,
    // [EditProductForSellFormNames.productImages]: data.productImages,
    [EditProductForSellFormNames.attributes]: attributes,
    [EditProductForSellFormNames.logistics]: logistics,
    [EditProductForSellFormNames.detail]: data.detail,
    [EditProductForSellFormNames.weight]: data.weight,
    [EditProductForSellFormNames.width]: data.width,
    [EditProductForSellFormNames.height]: data.height,
    [EditProductForSellFormNames.length]: data.length,
    [EditProductForSellFormNames.skus]: data.skus,
    [EditProductForSellFormNames.isPreOrder]:
      data.isPreOrder === true ? true : false,
    [EditProductForSellFormNames.daysToShip]: data.daysToShip || 7,
    [EditProductForSellFormNames.condition]: data.condition || 'NEW',
  });
};

export const scrollToFieldOptions = {
  behavior: (actions: any) => {
    actions.forEach(({ el, top }: any) => {
      let offsetTop = 0;
      if (el.scrollTop - top > 150) {
        el.scrollTop = top - 150;
      } else if (top - el.scrollTop > 150) {
        el.scrollTop = top + 150;
      } else {
        el.scrollTop = top - 70;
      }

      offsetTop = el.scrollTop - top;
      window.scrollBy(offsetTop, 0);
    });
  },
};

export const detectErrorFromShopee = (errorMsg: string) => {
  console.log('errorMsg', errorMsg)
  if( errorMsg.includes('error_sign')){
    return 'Xảy ra lỗi, Vui lòng thử lại!';
  }
  if( errorMsg.includes('Invalid logistic info')){
    return 'Thông tin vận chuyển không hợp lệ!';
  }
  if( errorMsg.includes('ShopeeUploadImageInfoResponse') ||  errorMsg.includes('upload_image')){
    return i18n.t('shopeeMessage.sku-image-not-valid');
  }
  if( errorMsg.includes('invalid field CategoryId')){
    return i18n.t('shopeeMessage.categoryEmpty');
  }
  if (
    errorMsg.includes('download image file in position') || 
    errorMsg.includes('All images download fail')
  ) {
    return i18n.t('shopeeMessage.product-image-not-valid');
  }

  if (errorMsg.includes('description_len no pass')) {
    return i18n.t('shopeeMessage.description-len');
  }

  if (errorMsg.includes('Image') && errorMsg.includes('download failed')
  ) {
    return i18n.t('shopeeMessage.sku-image-not-valid');
  }

  if (errorMsg.includes('tier_variation')) {
    return i18n.t('shopeeMessage.sku-variation');
  }

  if (errorMsg.includes('price_limit no pass')) {
    return i18n.t('shopeeMessage.sku-price');
  }

  if (errorMsg.includes('check logistics fail')) {
    return i18n.t('shopeeMessage.check-logistics-fail');
  }

  if (errorMsg.includes('logistic') && errorMsg.includes('not enabled')) {
    return i18n.t('shopeeMessage.logistics-not-enabled');
  }
  if (errorMsg.includes('days_to_ship')) {
    return i18n.t('shopeeMessage.days-to-ship');
  }

  if (errorMsg.includes('days_to_ship') && errorMsg.includes('pre-order')) {
    return i18n.t('shopeeMessage.days-to-ship');
  }

  return errorMsg.trim() || i18n.t("shopeeMessage.unknown");
};
