import { Button, ConfigProvider, Table, Tabs } from 'antd'
import { AxiosResponse } from 'axios'
import lodash, { parseInt } from 'lodash'
import { evaluate } from 'mathjs'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import EmptyDataFilter from '../../components/Empty/EmptyDataFilter'
import MainLayout from '../../components/Layout'
import M24Pagination from '../../components/Pagination/M24Pagination'
import { DEFAULT_PAGE_SIZE } from '../../core/config'
import { ConnectChannelInterface, ConnectShopInterface, PriceConfigureBody } from '../../Interface/ConnectShopInterface'
import { connectMarketRepository } from '../../repositories/ConnectMarketRepository'
import M24Notification from '../../utils/M24Notification'
import { ModalConfigAll } from './ModalConfigAll'
import { PriceInput } from './PriceInput'
import './style.scss'

interface Pagination {
	current?: number
	hideOnSinglePage?: boolean
	total?: number
	pageSize?: number
}

function PriceConfig() {
	const { t } = useTranslation()
	const [showProgressBar, setShowProgressBar] = useState<boolean>(false)
	const [activateTab, setActivateTab] = useState<string>('')
	const [integrationShops, setIntegrationShops] = useState<ConnectShopInterface[]>([])
	const [pagination, setPagination] = useState<Pagination>({})
	const [channels, setChannels] = useState<ConnectChannelInterface[]>([])
	const [searchParams, setSearchParams] = useSearchParams()
	const [isShowModalConfigAll, setIsShowModalConfigAll] = useState(false)
	const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([])
	const [preBody, setPreBody] = useState<PriceConfigureBody>()

	const filterFromParams: any = {
		page: Number(searchParams.get('page') || '1'),
		size: Number(searchParams.get('size') || DEFAULT_PAGE_SIZE),
	}

	const getChannels = () => {
		setShowProgressBar(true)
		connectMarketRepository
			.getMarketChannel()
			.then((response) => {
				setChannels(response)
				setActivateTab(response[0].code)
				setSearchParams({ channel: response[0].code, ...filterFromParams })
			})
			.catch((err) => {
				console.log(err)
			})
			.finally(() => setShowProgressBar(false))
	}

	useEffect(() => {
		if (activateTab) {
			getIntegrationShops()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activateTab])

	const getIntegrationShops = (filterChanging?: any) => {
		const filter: any = filterChanging || filterFromParams

		setShowProgressBar(true)
		setSearchParams({ chanel: activateTab, ...filter })

		const { page, size } = filter
		let limit = size || DEFAULT_PAGE_SIZE
		let offset = (size || 0) * (page - 1 || 0)

		connectMarketRepository
			.getChannelShops({ ...filter, limit, offset }, activateTab)
			.then((response: AxiosResponse<ConnectShopInterface[]>): void => {
				let currentPage = lodash.get(response, 'headers.x-page-number')
				setIntegrationShops(response.data)

				setPagination({
					hideOnSinglePage: true,
					current: parseInt(currentPage) + 1,
					total: parseInt(lodash.get(response, 'headers.x-total-count')),
					pageSize: parseInt(lodash.get(response, 'headers.x-page-size')),
				})
			})
			.catch((err: any) => {
				console.log(err)
			})
			.finally(() => setShowProgressBar(false))
	}

	const handleChangeTab = (key: any) => {
		setActivateTab(key)
		setSearchParams({ channel: key })
	}

	const validatePriceFomula = (price: string = '', integrationIds: string[], isConfigAll = false) => {
		if (String(price).trim() === '') {
			return true
		}

		let result = true
		if (price.includes('--')) {
			result = false
		} else if (price.includes('price')) {
			try {
				evaluate(price, {
					price: 1,
				})
			} catch (_: any) {
				result = false
			}
		} else {
			result = false
		}

		if (!result) {
			M24Notification.notifyError(t('message.titleFailed'), t('price-config.price-invalid'), '', 2)
		}

		if (!result && !isConfigAll) {
			const index: number = integrationShops.findIndex(
				(row: ConnectShopInterface): boolean => row.id === integrationIds[0]
			)
			onChangePrice('', index)
		}
		return result
	}

	const handleConfig = (priceFomula: string, integrationIds: string[], isConfigAll: boolean = false) => {
		setShowProgressBar(true)
		const body: PriceConfigureBody = {
			channel: activateTab,
			integrationIds: integrationIds,
			priceFomula: priceFomula.trim(),
		}

		setPreBody(body)

		if (!isConfigAll && lodash.isEqual(body, preBody)) {
			setShowProgressBar(false)
			return
		}

		const priceFomulaIsValid = validatePriceFomula(priceFomula, integrationIds, isConfigAll)

		if (!priceFomulaIsValid) {
			setShowProgressBar(false)
			return
		}

		connectMarketRepository
			.priceConfigure(body)
			.then(() => {
				if (isConfigAll) {
					setSelectedRowKeys([])
					getIntegrationShops()
				}

				if (priceFomula === '') {
					M24Notification.notifyWarning(t('message.warning'), t('price-config.price-not-empty'), '', 2)
				} else {
					M24Notification.notifySuccess(t('message.success'), t('price-config.create-success'), '', 2)
				}
			})
			.catch(() => {
				M24Notification.notifyError(t('message.titleFailed'), t(`message.try_again`), '', 2)
			})
			.finally(() => {
				setShowProgressBar(false)
				setIsShowModalConfigAll(false)
			})
	}

	const onFinishFormConfigAll = (values: any) => {
		console.log('values', values)
		handleConfig(values.priceFormula, selectedRowKeys, true)
	}

	const onChangePrice = (value: string, index: number) => {
		const dataSourceTemp: ConnectShopInterface[] = Array.from(integrationShops)
		dataSourceTemp[index].priceFormula = value
		setIntegrationShops(dataSourceTemp)
	}

	const columns = [
		{
			title: <span className='fsz-14 line-h-22 robotomedium txt-color-gray4'>{t('price-config.shopName')}</span>,
			dataIndex: 'name',
			key: 'name',
			width: '50%',
			render: (shopName: string) => {
				return <span className={'txt-size-h7 robotomedium wordbreakword'}>{shopName}</span>
			},
		},
		{
			title: <span className='fsz-14 line-h-22 robotomedium txt-color-gray4'>{t('price-config.price')}</span>,
			dataIndex: 'priceFomula',
			key: 'priceFomula',
			width: '50%',
			render: (_: string, row: ConnectShopInterface, index: number) => {
				console.log('index', index)
				return (
					<PriceInput
						value={integrationShops[index].priceFormula}
						rowId={row.id}
						index={index}
						handleConfig={handleConfig}
						onChangePrice={onChangePrice}
					/>
				)
			},
		},
	]

	const onSelect = (selectedRows: any[]): void => {
		const integrationIds: string[] = selectedRows.map((row: any) => row.id)
		setSelectedRowKeys(integrationIds)
	}

	const onClickOnRow = (record: ConnectShopInterface): void => {
		const rowSelected: string[] = Array.from(selectedRowKeys)
		const index = rowSelected.findIndex((row) => row === record.id)

		if (index === -1) {
			rowSelected.push(record.id)
		} else {
			rowSelected.splice(index, 1)
		}

		setSelectedRowKeys(rowSelected)
	}

	useEffect(() => {
		getChannels()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<>
			{console.log('integrationShops', integrationShops)}
		
			<MainLayout showProgressBar={showProgressBar} title={t('menu.price-config')}>
				<div className='price-config'>
					<Tabs type='card' defaultActiveKey={activateTab} onChange={handleChangeTab}>
						{channels.map((x: ConnectChannelInterface) => {
							return (
								<Tabs.TabPane tab={x.name} key={x.code}>
									<div className={'shop-list-header'}>
										<span className={'dpl-flex'}>
											<span className={'title'}>{t('price-config.shop-list')}</span>

											<span
												className={`mgl5 dpl-flex align-items-center c-badge ${
													integrationShops.length < 1 ? 'c-badge-no-data' : ''
												}`}>
												<span
													className={`${
														integrationShops.length < 1
															? 'c-badge-num-no-data'
															: 'c-badge-num'
													}`}>
													{pagination.total}
												</span>
											</span>
										</span>

										<Button
											onClick={() => setIsShowModalConfigAll(true)}
											disabled={selectedRowKeys.length === 0 || showProgressBar}
											className='m24-button'
											type='primary'>
											<i className='fa-solid fa-gear pdr8'></i>
											{t('price-config.config-all')}
										</Button>
									</div>

									<div className={'shop-list'}>
										<ConfigProvider
											renderEmpty={() => (
												<EmptyDataFilter description={t('price-config.empty-list')} />
											)}>
											<Table
												columns={columns}
												dataSource={integrationShops}
												pagination={false}
												rowClassName={'custom-row-hover'}
												bordered={false}
												rowSelection={{
													type: 'checkbox',
													selectedRowKeys,
													onSelect: (_: any, __: any, selectedRows: any) =>
														onSelect(selectedRows),
													onSelectAll: (_: any, selectedRows: any) => onSelect(selectedRows),
												}}
												rowKey={(row) => row.id}
												loading={showProgressBar}
												onRow={(record) => {
													return {
														onClick: () => onClickOnRow(record),
													}
												}}
											/>

											{integrationShops.length >= 1 && (
												<M24Pagination
													className={'mgbt5 mgt15'}
													filter={{ ...filterFromParams }}
													pagination={pagination}
													onPageChange={getIntegrationShops}
												/>
											)}
										</ConfigProvider>
									</div>
								</Tabs.TabPane>
							)
						})}
					</Tabs>
				</div>

				{isShowModalConfigAll && (
					<ModalConfigAll
						showProgressBar={showProgressBar}
						isShowModalConfigAll={isShowModalConfigAll}
						setIsShowModalConfigAll={setIsShowModalConfigAll}
						onFinishFormConfigAll={onFinishFormConfigAll}
					/>
				)}
			</MainLayout>
		</>
	)
}

export default PriceConfig

