import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Grid, Input, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { ConnectShopInterface } from '../../Interface/ConnectShopInterface'
import lodash, { isArray } from 'lodash'

type PendingProductFilterProps = {
	shopList: ConnectShopInterface[]
	searchParams: any
	setSearchParams: any
}
const { useBreakpoint } = Grid
function PendingProductFilter(props: PendingProductFilterProps) {
	const { t } = useTranslation()
	const { lg } = useBreakpoint()

	const [collapse, setCollapse] = useState(false)

	const activeShopList = props.searchParams.get('integrationIds')?.split(',') || []
	const [productNameFieldValue, setProductNameFieldValue] = useState<string>(props.searchParams.get('name'))
	const [showCleanFilter, setShowCleanFilter] = useState(false)

	const onResetFilter = (paramName: string = '') => {
		if (!paramName) {
			props.setSearchParams({})
			setProductNameFieldValue('')
			return
		}

		const { ...rest } = Object.fromEntries(props.searchParams)
		delete rest[paramName]
		props.setSearchParams({ ...rest })
	}

	const onSelectShop = (integrationId: string) => {
		switch (integrationId !== '') {
			case true:
				let shopList = [...activeShopList]
				const { ...rest } = Object.fromEntries(props.searchParams)
				if (shopList.includes(integrationId)) {
					shopList = shopList.filter((s) => s !== integrationId)
					if (shopList.length > 0) {
						props.setSearchParams({ ...rest, integrationIds: shopList.join(',') })
					} else {
						delete rest.integrationIds
						props.setSearchParams({ ...rest })
					}
				} else {
					shopList.push(integrationId)
					props.setSearchParams({ ...rest, integrationIds: shopList.join(',') })
				}
				break
			default:
				onResetFilter('integrationIds')
				break
		}
	}
	const handleCollapseFilter = () => {
		setCollapse(!collapse)
	}

	const onProductNameFieldValueChange = (e: React.ChangeEvent<HTMLInputElement>) =>
		setProductNameFieldValue(e.target.value)

	const findProductByName = () => {
		let filterTemp = {
			name: productNameFieldValue.trim() || '',
			integrationIds: activeShopList.join(','),
		}
		props.setSearchParams({
			...lodash.omitBy(filterTemp, (value) => !value),
		})
	}
	const handleSearch = () => findProductByName()

	useEffect(() => {
		setShowCleanFilter(!!productNameFieldValue || isArray(activeShopList))
	}, [activeShopList, productNameFieldValue])

	return (
		<Card
			title={
				<div className={'dpl-flex flex-row justify-content-between'} onClick={handleCollapseFilter}>
					<span>{t('warehouse.searchProduct')}</span>
					<span>
						{collapse ? <i className='fa-solid fa-angle-down' /> : <i className='fa-solid fa-angle-up' />}
					</span>
				</div>
			}
			className='filter-list-container'>
			{!collapse ? (
				<Row gutter={[0, 16]}>
					<Col xs={24}>
						<Row gutter={[32, 12]} align='middle'>
							<Col>
								<Row gutter={[6, 12]} align='middle'>
									<Col>
										<h4 className='txt-size-h7 robotoregular txt-color-black line-h-22 mgbt0'>
											{t('pendingProduct.shop-filter')} :
										</h4>
									</Col>
									<Col>
										<Button
											className={`status-package mg-bt-0 ${activeShopList.length === 0 ? 'active' : ''}`}
											onClick={() => onSelectShop('')}>
											{t('tickets.missing_quantity_all')}
										</Button>
									</Col>
									{props.shopList.map((shop: ConnectShopInterface, i) => (
										<Col key={`shop_${i}`}>
											<Button
												className={`status-package mg-bt-0 ${
													activeShopList.includes(shop.id) ? 'active' : ''
												}`}
												onClick={() => onSelectShop(shop.id)}>
												{shop.name}
											</Button>
										</Col>
									))}
								</Row>
							</Col>
							<Col>
								<Row gutter={[4, 0]} align='middle'>
									<Col>
										<h4 className='txt-size-h7 robotoregular txt-color-black line-h-22 mgbt0'>
											{t('orderDetail.productName')}:
										</h4>
									</Col>
									<Col>
										<Input
											type='text'
											className={`txt-size-h8 txt-color-secondary robotoregular border-radius4 line-h-20 ${
												lg ? 'input-fixed-width' : ''
											}`}
											placeholder={`${t('warehouse.fillProductNameField')}...`}
											onChange={(e) => onProductNameFieldValueChange(e)}
											value={productNameFieldValue}
											onPressEnter={handleSearch}
											allowClear
										/>
									</Col>
								</Row>
							</Col>
						</Row>
					</Col>
					<Col xs={24}>
						<Row justify='end'>
							{showCleanFilter && (
								<Col>
									<Button
										type='text'
										className='btn-filter-reset txt-size-h8 robotoregular txt-color-secondary'
										icon={<i className='fa-solid fa-rotate-left' />}
										onClick={() => onResetFilter()}>
										{t('filter.refresh')}
									</Button>
								</Col>
							)}
							<Col>
								<Button
									className='btn-df btn-df-secondary-bg-lemon robotomedium line-h-22 px-28 py-3 border-radius4'
									onClick={handleSearch}>
									{t('orders.button_search')}
								</Button>
							</Col>
						</Row>
					</Col>
				</Row>
			) : null}
		</Card>
	)
}
export default PendingProductFilter

