import { Avatar, Button, Col, Divider, Empty, List, Row, Tooltip } from 'antd'
import Paragraph from 'antd/lib/typography/Paragraph'
import { isEmpty } from 'lodash'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import EmptyDataFilter from '../../../components/Empty/EmptyDataFilter'
import Services from '../../../components/Services'
import TruncateText from '../../../components/TruncateText'
import { formatDateTime } from '../../../core/helpers/date-time'
import { formatMoneyByUnit } from '../../../core/helpers/string'
import { ProviderInterface } from '../../../Interface/AccountBalanceInterface'
import { ConnectionInterface } from '../../../Interface/ConnectionInterface'
import ShipmentsFilterInterface from '../../../Interface/ShipmentFilterInterface'
import ShipmentInterface from '../../../Interface/ShipmentInterface'
import ShipmentStatusInterface from '../../../Interface/ShipmentStatusInterface'
import EmptyCartImg from '../../../resources/images/empty-blue1.png'
import ShipmentImageDefault from '../../../resources/images/shipment-ubox-default.png'
import SkeletonOrders from '../../Orders/SkeletonOrders'
import HeaderList from './HeaderList'
interface ListShipmentProps {
	isSkeleton: boolean
	loading: boolean
	total: number
	filter: ShipmentsFilterInterface | {}
	accounts: ConnectionInterface[]
	statuses: ShipmentStatusInterface[]
	providers: ProviderInterface[]
	data: ShipmentInterface[]
	typeDataTable: string
	handleTypeTableData: (val: string) => void
}
const ShipmentList: React.FC<ListShipmentProps> = (props) => {
	const { isSkeleton, loading, handleTypeTableData, total, filter, data, accounts, statuses, providers, typeDataTable } = props
	const { t } = useTranslation()
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()
	const refCode = useRef<HTMLDivElement | null>(null)

	return (
		<div className=''>
			{!loading && !isSkeleton && (
				<List
					className='shipments-items'
					pagination={false}
					header={
						<HeaderList
							total={total}
							typeDataTable={typeDataTable}
							setTypeDataTable={(val: string) => handleTypeTableData(val)}
						/>
					}
					locale={{
						emptyText:
							!searchParams.get('code') &&
							!searchParams.get('originalId') &&
							!searchParams.get('merchantName') &&
							!searchParams.get('waybill') &&
							!searchParams.get('statuses') &&
							!searchParams.get('createdFrom') &&
							!searchParams.get('createdTo') &&
							!searchParams.get('providerUsername') &&
							!searchParams.get('provider') ? (
								<Empty
									className={`shipments-empty ${isSkeleton || loading ? 'dpl-none' : ''}`}
									image={EmptyCartImg}
									description={
										<>
											<span className='robotoregular fsz-14 line-h-22 txt-color-gray8'>
												{isEmpty(filter) ? t('shipments.noResult') : t('shipments.empty')}
											</span>
											<Button
												className='btn-df btn-df-secondary-bg-blue fsz-14 line-h-22 robotomedium'
												icon={<i className='fa-regular fa-plus mg-r-10'></i>}
												onClick={() => navigate('/shipments/create', { replace: true })}>
												{t('shipments.createShipments')}
											</Button>
										</>
									}
								/>
							) : (
								<EmptyDataFilter description={t('shipments.filterEmpty')} />
							),
					}}
					dataSource={data}
					renderItem={(item: ShipmentInterface, idx) => {
						const account = accounts.find(
							(acc: ConnectionInterface) =>
								acc.provider.code === item.provider && acc.name === item.providerUsername
						)
						const status = statuses.find((stt: ShipmentStatusInterface) => stt.code === item.status)
						const provider = providers.find((prod: ProviderInterface) => prod.code === item.provider)
						let currency
						const currencyJsonStr = provider?.currency
						if (currencyJsonStr) {
							currency = JSON.parse(currencyJsonStr).code
						}
						return (
							<List.Item
								key={item.id}
								onClick={() => navigate(`/shipments/${item.code}`)}
								className={`cursor-pointer ${idx % 2 === 0 ? 'row-dark' : 'row-light'}`}>
								<List.Item.Meta
									avatar={
										<Avatar
											src={item.image ? item.image : ShipmentImageDefault}
											shape='square'
											size={72}
											className='bd-none-impt rad-6'
										/>
									}
									title={
										<Row align='middle' justify='space-between'>
											<Col span={24}>
												<Row align='middle' className='mg-bt-4'>
													<Col span={6} className='flex' ref={refCode}>
														<Link to={`/shipments/${item.code}`} className='mg-r-16'>
															<Paragraph
																className='robotomedium mg-0 txt-color-blue6 fsz-14 line-h-22 order-heading whitespace'
																copyable={{
																	text: item.code,
																	icon: [
																		<Tooltip
																			title={
																				<span className='robotoregular fsz-12 txt-color-black line-h-20'>
																					Copy
																				</span>
																			}
																			color='#FBB739'>
																			<i className='far fa-copy txt-color-black line-h-14 w-12'></i>
																		</Tooltip>,
																		<Tooltip
																			title={
																				<span className='robotoregular fsz-12 txt-color-black line-h-20'>
																					Copied
																				</span>
																			}
																			color='#FBB739'>
																			<i className='far fa-check fsz-12 line-h-14 w-12'></i>
																		</Tooltip>,
																	],
																	tooltips: [false, false],
																}}>
																{`#${item.code}`}
															</Paragraph>
														</Link>
														<span className='flex align-items-center '>
															<Tooltip
																color='#FBB739'
																title={
																	<span className='robotoregular fsz-12 txt-color-black line-h-20'>
																		{t('shipments.quantifyTooltip')}
																	</span>
																}>
																<span className='mg-r-3 fsz-14 line-h-22'>{`${
																	item.orderedQuantity ? item.orderedQuantity : '---'
																} / ${
																	item.receivedQuantity ? item.receivedQuantity : '---'
																}`}</span>
															</Tooltip>
														</span>
													</Col>

													<Col span={18}>
														{item.services.length > 0 && (
															<div className='mg-r-18 flex'>
																<span className='txt-color-gray4 fsz-14 line-h-22 whitespace'>{`${t(
																	'shipments.services'
																)}:`}</span>
																<Services
																	data={item.services}
																	className='fsz-14 line-h-22'
																	dividerClass='service-divider mg-bt-4'
																/>
															</div>
														)}
													</Col>
												</Row>
											</Col>

											<Col span={24}>
												<Row align='top'>
													<Col span={6} className='flex flex-col'>
														<div className='flex align-items-center mg-bt-4'>
															<i className='fa-light fa-store mg-r-4' />
															<span className='txt-color-black fsz-12 line-h-20 whitespace'>
																{item.merchantName ? item.merchantName : '---'}
															</span>
														</div>
														<div className={'dpl-flex align-items-center fsz-14 line-h-22'}>
															<Avatar
																size={20}
																src={account?.provider?.logo}
																shape={'circle'}
																className='bd-none-impt mg-r-4'
															/>
															<span className='txt-color-black fsz-14 line-h-22'>
																{account?.provider.code}
															</span>
															<Divider
																type={'vertical'}
																className={'mx-10 bd-color-black h-16 line-h-22 mx-4'}
															/>
															<span className={'robotoregular fsz-14 line-h-22'}>
																{account?.name}
															</span>
														</div>
													</Col>

													<Col span={18}>
														<Row align='middle' className='mg-bt-4'>
															<Col flex='0 0 auto' className='flex align-items-center'>
																<div className='flex align-items-center'>
																	<span className='txt-color-gray4 fsz-14 line-h-22 mg-r-2 whitespace mg-r-2'>
																		<span className='mg-r-1'>
																			{`${t('shipments.financeInfo')}`}:
																		</span>
																		<Tooltip
																			color='#FBB739'
																			title={
																				<span className='robotoregular fsz-12 txt-color-black line-h-20'>
																					{`${t('shipments.provisionalAmount')} / ${t(
																						'shipments.provisionalAmountTooltip'
																					)}`}
																				</span>
																			}>
																			<span className='fsz-14 line-h-22 txt-color-orange1 whitespace'>{`${
																				item.totalFee
																					? formatMoneyByUnit(item.totalFee, currency)
																					: '---'
																			} / ${
																				item.totalValue
																					? formatMoneyByUnit(
																							item.totalValue,
																							item.currency
																					  )
																					: '---'
																			}`}</span>
																		</Tooltip>
																	</span>{' '}
																</div>
																<Divider
																	type={'vertical'}
																	className={'mx-10 bd-color-black h-16 line-h-22'}
																/>
															</Col>
															<Col flex='0 0 auto' className='flex align-items-center'>
																<span className='txt-color-gray4 fsz-14 line-h-22 mg-r-2 whitespace'>{`${t(
																	'shipments.financeTotalNeedToPaid'
																)}:`}</span>
																<span className='txt-color-red2 fsz-14 line-h-22'>{`${
																	item.totalUnpaid
																		? formatMoneyByUnit(item.totalUnpaid)
																		: formatMoneyByUnit(0)
																}`}</span>
																<Divider
																	type={'vertical'}
																	className={'mx-10 bd-color-black h-16 line-h-22'}
																/>
															</Col>
															<Col flex='0 0 auto' className='flex align-items-center'>
																<span className='fsz-14 line-h-22'>
																	<Tooltip
																		color='#FBB739'
																		title={
																			<span className='robotoregular fsz-12 txt-color-white line-h-20'>
																				{t('shipments.createAtTooltip')}
																			</span>
																		}>
																		<span className='fsz-14 line-h-22 txt-color-gray'>
																			{item.timestamp
																				? formatDateTime(item.timestamp)
																				: '---'}{' '}
																		</span>
																	</Tooltip>
																</span>
															</Col>
														</Row>
														<Row align='middle'>
															<Col flex={'0 0 auto'} className='flex'>
																<div className='flex'>
																	<span className='txt-color-gray4 fsz-14 line-h-22 mg-r-3 whitespace'>
																		{t('shipments.refCustomerCodeLabel')}:
																	</span>
																	<TruncateText
																		className='fsz-14 line-h-22'
																		defaultValue={
																			item.refShipmentCode ? item.refShipmentCode : '---'
																		}
																	/>
																</div>
															</Col>
														</Row>
													</Col>
												</Row>
											</Col>
										</Row>
									}
								/>
								<div className='shipment-status flex justify-content-end'>
									<span
										className='line-h-22 rad-25 txt-color-white px-16 py-4 txt-capitalize fsz-14  robotomedium '
										style={{ backgroundColor: `${status?.color ? status.color : '#0082E6'}` }}>
										{status?.name}
									</span>
								</div>
							</List.Item>
						)
					}}></List>
			)}
			{(loading || isSkeleton) && <SkeletonOrders data={data} loading={isSkeleton || loading} />}
		</div>
	)
}

export default ShipmentList