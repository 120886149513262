import { Repository } from '../core/repositories/Repository'
import { url } from '../core/helpers/string'
import { httpConfig } from '../core/config/http'
import { API_BASE_URL } from '../core/config'
import { AxiosResponse } from 'axios'
import { BodyEditStoredProductInterface, TransportService } from '../Interface/StoredProductInterface'
import { ProductForSellInterface } from '../Interface/ProductInterface'

export class WarehouseRepository extends Repository {
  constructor() {
    super(httpConfig);
    this.setBaseURL(url(API_BASE_URL, ''));
  }

  public getStoredProducts(filter: any) {
    return this.http.get(`/personal-products`, {
      params: { ...filter },
    });
  }

  public getStoredProductDetail(
    id: string
  ): Promise<AxiosResponse<ProductForSellInterface>> {
    return this.http.get(`/personal-products/${id}`);
  }

  public getTransportServices(
    body: any
  ): Promise<AxiosResponse<TransportService[]>> {
    return this.http.get(
      `/integrations/logistics?channel=${body.chanel}&integrationId=${body.integrationId}`
    );
  }

  public editStoredProduct(
    id: string,
    body: BodyEditStoredProductInterface
  ): Promise<AxiosResponse> {
    return this.http.patch(`/personal-products/${id}`, body);
  }

  public deleteStoredProduct(productsId: string[]) {
    return this.http.delete(`personal-products/${productsId}`);
  }

  public getCurencise() {
    return this.http.get('/currencies');
  }

 
}

export const warehouseRepository: WarehouseRepository = new WarehouseRepository()

