import { Avatar, Button, Col, Divider, Form, Input, Row, Select } from 'antd'
import { t } from 'i18next'
import lodash, { isArray, isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { ProviderInterface } from '../../Interface/AccountBalanceInterface'
import ShipmentsFilterInterface from '../../Interface/ShipmentFilterInterface'
import ShipmentStatusInterface from '../../Interface/ShipmentStatusInterface'
const { Item } = Form
interface FilterProps {
	filter: any
	statuses: any
	accounts: any
	providers: ProviderInterface[]
	peerPaymentAndShipmentStatuses: any
	partnerOfShipments: any
	curPartner: any 
	account: any
	filterChangeHandler: (obj: any) => void
	handleFilter: (params: ShipmentsFilterInterface | {}) => void
}
const Filter: React.FC<FilterProps> = (props) => {
	const {
		statuses,
		accounts,
		handleFilter,
		filter,
		filterChangeHandler,
		peerPaymentAndShipmentStatuses,
		partnerOfShipments,
	} = props
	const [form] = Form.useForm()
	const [showFilter, setShowFilter] = useState(true)
	const [searchParams, setSearchParams] = useSearchParams()
	const [checkedStatuses, setCheckedStatuses] = useState<string[]>(searchParams.get('statuses')?.split(',') || [])
	const [expand, setExpand] = useState(false)
	const [showCleanFilter, setShowCleanFilter] = useState(false)
	const [curPeerPaymentStatus, setCurPeerPaymentStatus] = useState<any>()
	const [curPartnerOfPayment, setcurPartnerOfPayment] = useState<any>()
	const [curPartnerOfShipment, setcurPartnerOfShipment] = useState<any>()

	useEffect(() => {
		const currentStatusFromParams = searchParams.get('peerPaymentStatus')
		if (currentStatusFromParams) {
			const currenPeerPaymentStatusObj = peerPaymentAndShipmentStatuses.find(
				(item: any) => currentStatusFromParams === item.code
			)

			if (currenPeerPaymentStatusObj) {
				setCurPeerPaymentStatus(currenPeerPaymentStatusObj)
			}
		} else {
			setCurPeerPaymentStatus({})
		}
	}, [peerPaymentAndShipmentStatuses, searchParams])

	useEffect(() => {
		const currentPeerPaymentUsernameFromParams = searchParams.get('peerPaymentUsername')
		const currentPeerPaymentProviderFromParams = searchParams.get('peerPaymentProvider')
		if (currentPeerPaymentUsernameFromParams && currentPeerPaymentProviderFromParams) {
			const currenPartnerOfPaymentObj = partnerOfShipments.find(
				(item: any) =>
					currentPeerPaymentUsernameFromParams === item?.connection.name &&
					currentPeerPaymentProviderFromParams === item?.connection.provider.code
			)

			if (currenPartnerOfPaymentObj) {
				setcurPartnerOfPayment(currenPartnerOfPaymentObj)
			}
		} else {
			setcurPartnerOfPayment(null)
		}
	}, [partnerOfShipments, searchParams])

	useEffect(() => {
		const currentProviderUsernameFromParams = searchParams.get('providerUsername')
		const currentProviderFromParams = searchParams.get('provider')
		if (currentProviderUsernameFromParams && currentProviderFromParams) {
			const currenPartnerOfPaymentObj = accounts.find(
				(item: any) =>
					currentProviderUsernameFromParams === item?.connection.name &&
					currentProviderFromParams === item?.connection.provider.code
			)

			if (currenPartnerOfPaymentObj) {
				setcurPartnerOfShipment(currenPartnerOfPaymentObj)
			}
		} else {
			setcurPartnerOfShipment(null)
		}
	}, [accounts,searchParams])

	useEffect(() => {
		form.setFieldsValue({
			code: searchParams.get('code'),
			peerPaymentCode: searchParams.get('peerPaymentCode'),
			originalReceipt: searchParams.get('originalReceipt'),
		})
	}, [searchParams, setSearchParams, form, curPartnerOfPayment, curPartnerOfShipment, curPeerPaymentStatus])

	const handleChangeStatuses = (key: string, selected: boolean) => {
		if (selected) {
			setCheckedStatuses([...checkedStatuses, key])
			filterChangeHandler({ statuses: [...checkedStatuses, key] })
		} else {
			setCheckedStatuses(checkedStatuses.filter((stt: string) => stt !== key))
			filterChangeHandler({ statuses: checkedStatuses.filter((stt: string) => stt !== key) })
		}
	}

	const cleanFilter = () => {
		form.resetFields()
		setCheckedStatuses([])
		handleFilter({})
		setCurPeerPaymentStatus(undefined)
		setcurPartnerOfPayment(undefined)
		setcurPartnerOfShipment(undefined)
	}

	const getFormState = () => {
		const providerUsername = curPartnerOfShipment?.connection.name ?? ''
		const provider = curPartnerOfShipment?.connection.provider.code ?? ''
		const peerPaymentUsername = curPartnerOfPayment?.connection.name ?? ''
		const peerPaymentProvider = curPartnerOfPayment?.connection.provider.code ?? ''
		const filter = lodash.omitBy(
			{
				...form.getFieldsValue(true),
				statuses: checkedStatuses.join(','),
				providerUsername: providerUsername,
				provider: provider,
				peerPaymentStatus: curPeerPaymentStatus?.code,
				peerPaymentUsername: peerPaymentUsername,
				peerPaymentProvider: peerPaymentProvider,
			},
			(o: any, key: string) => {
				return isEmpty(o) || key === 'partner' || key === 'account'
			}
		)

		return filter
	}

	const onFinish = () => {
		handleFilter(getFormState())
	}

	useEffect(() => {
		setShowCleanFilter(
			!lodash.isEmpty(
				lodash.pickBy(filter, (val: any) => {
					if (isArray(val)) {
						return val.every((e) => e) && !isEmpty(val)
					}
					return !isEmpty(val)
				})
			)
		)
	}, [filter])

	const handleChangePeerPaymentStatus = (value: string) => {
		const curPeerPaymentSttObj = peerPaymentAndShipmentStatuses.find((item: any) => item.id === value)
		setCurPeerPaymentStatus(curPeerPaymentSttObj)
		filterChangeHandler({ peerPaymentStatus: curPeerPaymentSttObj?.code })
	}

	const handleChangeAccount = (value: string) => {
		const partnerOfShipmentObj = accounts.find((item: any) => item?.connection?.id === value)
		setcurPartnerOfShipment(partnerOfShipmentObj)
		filterChangeHandler({
			providerUsername: partnerOfShipmentObj?.connection.name,
			provider: partnerOfShipmentObj?.connection.provider.code,
		})
	}

	const handleChangePartnerOfPayment = (value: string) => {
		const partnerOfPaymentObj = partnerOfShipments.find((item: any) => item?.connection?.id === value)
		setcurPartnerOfPayment(partnerOfPaymentObj)
		filterChangeHandler({
			peerPaymentUsername: partnerOfPaymentObj?.connection.name,
			peerPaymentProvider: partnerOfPaymentObj?.connection.provider.code,
		})
	}

	return (
		<div className='peerpayments-filter pd-16'>
			<div
				className={`flex align-items-center justify-content-between cursor-pointer ${
					showFilter ? 'mg-bt-16' : ''
				}`}
				onClick={() => setShowFilter(!showFilter)}
			>
				<span className='robotomedium fsz-16 line-h-24 txt-color-black'>{t('peerpayments.filterTitle')} </span>
				<i
					className={`fa-solid fa-angle-${showFilter ? 'up' : 'down'} cursor-pointer`}
					onClick={() => setShowFilter(!showFilter)}
				></i>
			</div>
			{showFilter && (
				<>
					<div className={`dpl-flex align-items-baseline flex-wrap ${expand ? 'mg-bt-12' : ''}`}>
						<span className={'txt-size-h7 robotoregular txt-color-black mgr10'}>
							{t('orders.order_status')}:{' '}
						</span>
						{statuses?.map(({ code, name }: ShipmentStatusInterface) => {
							const selected = checkedStatuses && checkedStatuses.includes(code) ? true : false
							return (
								<span
									className={`txt-capitalize ${
										selected ? 'status-package active' : 'status-package'
									}`}
									key={code}
									onClick={() => {
										handleChangeStatuses(code, !selected)
									}}
								>
									{name}
								</span>
							)
						})}
					</div>
					{expand && (
						<Form
							name='peerpayments-form'
							className={`peerpayments-form ${expand ? 'mg-bt-12' : ''}`}
							form={form}
							onFinish={onFinish}
							onValuesChange={(changedValues, allValues) => {
								filterChangeHandler(allValues)
							}}
						>
							<Row gutter={[32, 8]}>
								<Col span={8}>
									<Item
										label={`${t('peerpayments.paymentRequestStatus')}:`}
										colon={false}
										labelAlign='left'
									>
										<Select
											className='fsz-14 select-hover'
											value={curPeerPaymentStatus ? curPeerPaymentStatus.id : undefined}
											placeholder={`${t('peerpayments.selectPaymentRequestStatus')}`}
											allowClear
											onChange={handleChangePeerPaymentStatus}
										>
											{peerPaymentAndShipmentStatuses.map((x: any) => {
												return (
													<Select.Option key={x.id} value={x.id} className='fsz-14'>
														{x.name}
													</Select.Option>
												)
											})}
										</Select>
									</Item>
								</Col>
								<Col span={8}>
									<Item
										label={`${t('peerpayments.accountPayment')}:`}
										colon={false}
										labelAlign='left'
									>
										<Select
											value={curPartnerOfPayment?.connection.id ?? undefined}
											onChange={handleChangePartnerOfPayment}
											placeholder={`${t('peerpayments.selectAccountPayment')}`}
											allowClear
										>
											{partnerOfShipments.map((x: any) => {
												return (
													<Select.Option key={x.connection.id} value={x.connection.id}>
														<Row className={'dpl-flex align-items-center'}>
															<Avatar
																size={20}
																src={lodash.get(x, 'connection.provider.logo', '')}
																shape={'circle'}
																className='bd-none-impt'
															></Avatar>
															<span className={'mgl5 robotoregular fsz-14'}>
																{lodash.get(x, 'connection.provider.code', '')}
															</span>
															<Divider type={'vertical'} className={'bd-color-black'} />
															<span className={'robotoregular fsz-14'}>
																{lodash.get(x, 'connection.name', '')}
															</span>
														</Row>
													</Select.Option>
												)
											})}
										</Select>
									</Item>
								</Col>

								<Col span={8}>
									<Item
										label={`${t('peerpayments.accountProvider')}:`}
										colon={false}
										labelAlign='left'
									>
										<Select
											onChange={handleChangeAccount}
											value={curPartnerOfShipment?.connection.id ?? undefined}
											placeholder={`${t('peerpayments.selectAccountProvider')}`}
											allowClear
											className='fsz-14'
										>
											{accounts.map((x: any) => {
												return (
													<Select.Option key={x.connection.id} value={x.connection.id}>
														<Row className={'dpl-flex align-items-center'}>
															<Avatar
																size={20}
																src={lodash.get(x, 'connection.provider.logo', '')}
																shape={'circle'}
																className='bd-none-impt'
															></Avatar>
															<span className={'mgl5 robotoregular fsz-14'}>
																{lodash.get(x, 'connection.provider.code', '')}
															</span>
															<Divider type={'vertical'} className={'bd-color-black'} />
															<span className={'robotoregular fsz-14'}>
																{lodash.get(x, 'connection.name', '')}
															</span>
														</Row>
													</Select.Option>
												)
											})}
										</Select>
									</Item>
								</Col>

								<Col span={8}>
									<Item
										label={`${t('peerpayments.orderCode')}:`}
										labelAlign='left'
										colon={false}
										name='code'
									>
										<Input allowClear placeholder={t('peerpayments.findByOrderCode')} />
									</Item>
								</Col>

								<Col span={8}>
									<Item
										label={`${t('peerpayments.paymentRequestCode')}:`}
										labelAlign='left'
										colon={false}
										name='peerPaymentCode'
									>
										<Input allowClear placeholder={t('peerpayments.findByPaymentRequestCode')} />
									</Item>
								</Col>

								<Col span={8}>
									<Item
										label={`${t('peerpayments.billCode')}:`}
										labelAlign='left'
										colon={false}
										name='originalReceipt'
									>
										<Input allowClear placeholder={t('peerpayments.findByBillCode')} />
									</Item>
								</Col>
							</Row>
						</Form>
					)}

					<div className='peerpayments-form__actions'>
						<span className='cursor-pointer fsz-12' onClick={() => setExpand(!expand)}>
							<i className={`mg-r-6 fa-solid fa-angle-${expand ? 'up' : 'down'}`}></i>
							<span className='fsz-12 line-h-20 txt-color-black robotoregular'>
								{/* {t('peerpayments.expandFilter')} */}
								Tìm kiếm mở rộng
							</span>
						</span>
						<div className='buttons'>
							{showCleanFilter && (
								<span
									className='buttons-clear fsz-14 line-h-22 mg-r-12 robotoregular'
									onClick={cleanFilter}
								>
									<i className='fa-solid fa-arrow-rotate-right fsz-12 mg-r-6'></i>
									<span className='fsz-12'>{t('peerpayments.cleanFilter')}</span>
								</span>
							)}
							<Button
								className='btn-df btn-df-secondary-bg-lemon fsz-14 line-h-22 px-24 py-4'
								htmlType='submit'
								form='peerpayments-form'
								onClick={onFinish}
							>
								{t('peerpayments.submitFilter')}
							</Button>
						</div>
					</div>
				</>
			)}
		</div>
	)
}

export default Filter