import { Image, Steps } from 'antd'
import lodash from 'lodash'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { formatDateTime } from '../../../../core/helpers/date-time'
import ShipmentInterface from '../../../../Interface/ShipmentInterface'
import ShipmentStatusInterface from '../../../../Interface/ShipmentStatusInterface'
import { HistoryOrder } from '../../../OrderDetail'
import StepIconFinishedImg from '../../../../resources/images/finish-step.png'
import StepIconImg from '../../../../resources/images/StepIcon.png'
import './style.scss'
import Skeleton from 'react-loading-skeleton'

const {Step} = Steps
interface TrackOrderProps {
    statuses: ShipmentStatusInterface[]
    shipment?: ShipmentInterface
    milestones: any
    loading: boolean
    handleTrackOrder: () => void
}

const TrackOrder:React.FC<TrackOrderProps> = (props) => {
    const {statuses, loading, milestones, handleTrackOrder} = props
  	const {t} = useTranslation()
    useEffect(() => {
        handleTrackOrder()
    }, [])

    const customDot = (dot: any, { status, index }: any) => {
        return  !loading ? <Image preview={false} src={status !== 'process' ? StepIconFinishedImg : StepIconImg} /> : <Skeleton width={10} height={10} circle/>
    }

      const CustomDescription = ({timestamp, handlingTime}: {timestamp: any, handlingTime: any}) => (
        <span className="dpl-flex flex-col align-items-start justify-content-start fsz-12 line-h-20">
            <span className="robotoregular  txt-color-gray4">{!loading ? timestamp : <Skeleton width={80}/>}</span>
            <span className="robotoregular txt-color-gray4">{!loading ? `(${handlingTime})` : <Skeleton width={180}/>}</span>
        </span>
      )

  return (
        <Steps className="track-order" current={0} progressDot={customDot} direction="vertical">
            {milestones && milestones.map(({status, timestamp, handlingTime, id} : HistoryOrder) =>{
                const nameOfStatus = lodash.get(statuses.find(({code}: any) => code === status), 'name', '---')
                const timestampRendered = lodash.isNull(timestamp) ? t('orderDetail.undefined') : formatDateTime(timestamp)
                const handlingTimeRendered = lodash.isNull(handlingTime) ? t('orderDetail.undefined') : `${handlingTime} ngày`
                return (
                    <Step key={id}  title={<span className='robotomedium txt-color-black fsz-12 line-h-20 txt-capitalize'>{!loading ? nameOfStatus : <Skeleton width={80}/>}</span>}
                    description={<CustomDescription timestamp={timestampRendered} handlingTime={handlingTimeRendered}/>} />)}
                )
            }
        </Steps>
  )
}

export default TrackOrder