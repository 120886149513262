import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useParams, useSearchParams } from 'react-router-dom'
import MainLayout from '../../../components/Layout'
import { ProviderInterface } from '../../../Interface/AccountBalanceInterface'
import { ClaimInterface } from '../../../Interface/ClaimInterface'
import MarketPalaceInterface from '../../../Interface/MarketPalaceInterface'
import ShipmentFeeInterface from '../../../Interface/ShipmentFeeInterface'
import ShipmentInterface from '../../../Interface/ShipmentInterface'
import ShipmentPackageInterface from '../../../Interface/ShipmentPackageInterface'
import ShipmentStatusInterface from '../../../Interface/ShipmentStatusInterface'
import { categoryRepository } from '../../../repositories/CategoryRepository'
import { claimRepository } from '../../../repositories/ClaimRepository'
import { shipmentRepository } from '../../../repositories/ShipmentRepository'
import M24ErrorUtils from '../../../utils/M24ErrorUtils'
import M24Notification from '../../../utils/M24Notification'
import Finance from './Finance'
import Heading from './Heading'
import Information from './Information'
import './shipment.style.scss'
import TabLayout from './TabLayout'
import TrackOrder from './TrackOrder'

export interface PaginationInterface {
	pageSize: number
	current: number
	total: number
}
export interface UpdatableInterface {
	refShipment: boolean
	refCustomer: boolean
	industryGroup: boolean
	personalNote: boolean
	productInfo: boolean
	other: boolean
}
const Shipment = () => {
	const { t } = useTranslation()
	const { code } = useParams()
	const [shipment, setShipment] = useState<ShipmentInterface>()
	const [loading, setLoading] = useState(false)
	const [statuses, setStatuses] = useState<ShipmentStatusInterface[]>([])
	const [marketPalaces, setMarketPalaces] = useState<MarketPalaceInterface[]>([])
	const [milestones, setMilestones] = useState([])
	const [fees, setFees] = useState<ShipmentFeeInterface[]>([])
	const [providers, setProviders] = useState<ProviderInterface[]>([])
	const [expand, setExpand] = useState(true)
	const [updatable, setUpdatable] = useState<UpdatableInterface>()
	const [currency, setCurrency] = useState('')
	const [canceling, setCanceling] = useState(false)
	const [cancelable, setCancelable] = useState(false)
	const [products, setProducts] = useState([])
	const [transactions, setTransactions] = useState([])
	const [loadingFees, setLoadingFees] = useState(false)
	const [packages, setPackages] = useState<ShipmentPackageInterface[]>([])
	const [loadingPackages, setLoadingPackages] = useState(false)
	const [claims, setClaims] = useState<ClaimInterface[]>([])
	const [loadingClaims, setLoadingClaims] = useState(false)
	const [productUpdatable, setProductUpdatable] = useState(true)
	const [searchParams, ] = useSearchParams()
	const [createAbleWaybill, setCreateAbleWaybill] = useState(false)

	const fetchDetails = useCallback(async () => {
		setLoading(true)
		try {
			const res = await shipmentRepository.getShipmentDetails(code!)
			setShipment(res)
			setProducts(res.products)
			setLoading(false)
		} catch (err) {
			setLoading(false)
		}
	}, [code])

	const getStatuses = useCallback(async () => {
		try {
			const res = await categoryRepository.getShipmentStatuses()
			setStatuses(res)
		} catch (err) {}
	}, [])

	const getMarketplaces = useCallback(async () => {
		try {
			const res = await categoryRepository.getMarketPlaces()
			setMarketPalaces(res)
		} catch (err) {}
	}, [])

	const handleTrackOrder = useCallback(async () => {
		if (shipment?.providerUsername && shipment?.provider) {
			try {
				const res = await shipmentRepository.getShipmentMilestone(code!, shipment?.providerUsername, shipment?.provider)
				setMilestones(res)
			} catch (err) {}
		}
	}, [code, shipment?.provider, shipment?.providerUsername])

	const getFees = useCallback(async () => {
		setLoadingFees(true)
		if (shipment?.providerUsername && shipment?.provider) {
			try {
				const res = await shipmentRepository.getFees(code!, shipment?.providerUsername!, shipment?.provider!)
				setFees(res)
				setLoadingFees(false)
			} catch (err) {
				setLoadingFees(false)
			}
		}
	}, [code, shipment?.provider, shipment?.providerUsername])

	const getProviders = useCallback(async () => {
		try {
			const res = await categoryRepository.getProvider()
			setProviders(res)
		} catch (err) {}
	}, [])

	const cancelShipment = useCallback(async () => {
		setCanceling(true)
		try {
			await shipmentRepository.cancelShipment(code!)
			M24Notification.notifySuccess('', t('shipment.cancelSuccess'), '', 6)
			fetchDetails()
			handleTrackOrder()
			getFees()
			setCanceling(false)
		} catch (err) {
			M24ErrorUtils.showError(t, err)
			setCanceling(false)
		}
	}, [code, fetchDetails, handleTrackOrder, t, getFees])

	useEffect(() => {
		handleTrackOrder()
		getFees()
	}, [handleTrackOrder, getFees])

	useEffect(() => {
		getStatuses()
		getMarketplaces()
		getProviders()
	}, [getMarketplaces, getStatuses, getProviders])

	useEffect(() => {
		fetchDetails()
	}, [fetchDetails])

	useEffect(() => {
		const statusObj = statuses.find((stt: ShipmentStatusInterface) => stt.code === shipment?.status)
		if (shipment?.status !== 'CANCELLED') {
			const updatable = !!statusObj?.updatable
			setUpdatable({
				refShipment: updatable,
				refCustomer: updatable,
				industryGroup: updatable,
				personalNote: updatable,
				productInfo: updatable,
				other: updatable,
			})
		} else {
			setUpdatable({
				refShipment: true,
				refCustomer: true,
				industryGroup: false,
				personalNote: true,
				productInfo: false,
				other: false,
			})
		}
		setCancelable(statusObj?.cancellable)
		setProductUpdatable(!!statusObj?.productUpdatable)
		setCreateAbleWaybill(!!statusObj?.updatable)
	}, [shipment?.status, statuses])

	useEffect(() => {
		const provider = providers.find((prod: ProviderInterface) => prod.code === shipment?.provider)
		if (provider?.currency) {
			setCurrency(JSON.parse(provider.currency).code)
		}
	}, [providers, shipment?.provider])

	const getTransactions = useCallback(async () => {
		if (shipment?.providerUsername && shipment?.provider) {
			try {
				const res = await shipmentRepository.getFinancial(code!, shipment?.providerUsername, shipment?.provider)
				setTransactions(res)
			} catch (err) {}
		}
	}, [code, shipment?.provider, shipment?.providerUsername])

	const getShipmentPackages = useCallback(async () => {
		setLoadingPackages(true)
		try {
			const res = await shipmentRepository.getShipmentPackages(code!)
			setPackages(res)
			setLoadingPackages(false)
		} catch (err) {
			setLoadingPackages(false)
		}
	}, [code])

	const getClaimOfShipment = useCallback(async () => {
		if (shipment?.providerUsername && shipment?.provider) {
			setLoadingClaims(true)
			try {
				const res = await claimRepository.getClaims(shipment?.provider, shipment?.providerUsername, { code })
				setClaims(res.data)
				setLoadingClaims(false)
			} catch (err: any) {
				M24ErrorUtils.showError(t, err.response)
				setLoadingClaims(false)
			}
		}
	}, [code, shipment?.provider, shipment?.providerUsername, t])

	useEffect(() => {
		getTransactions()
		getShipmentPackages()
		getClaimOfShipment()
	}, [getShipmentPackages, getTransactions])

	useEffect(() => {
		window.scrollTo({ top: document.documentElement.scrollHeight, behavior: 'smooth' })
	}, [searchParams.get('tab')])

	return (
		<MainLayout title={`${t('menu.shipmenDetails')} #${code}`}>
			<Heading
				shipment={shipment}
				statuses={statuses}
				marketPalaces={marketPalaces}
				providers={providers}
				cancelShipment={cancelShipment}
				canceling={canceling}
				currencyMarketPalace={shipment?.currency}
				cancelable={cancelable}
				loading={loading}
			/>

			<div className='shipment-details'>
				<div className='shipment-details__text'>
					<div className='shipment-details__text-top'>
						<Information shipment={shipment} expand={expand} updatable={updatable} loading={loading} />
						<Finance
							shipment={shipment}
							fees={fees}
							providers={providers}
							expand={expand}
							loading={loading}
							loadingFees={loadingFees}
						/>
						<div className='shipment-collapse' onClick={() => setExpand(!expand)}>
							<i className={`fa-solid fa-angle-${expand ? 'up' : 'down'}`}></i>{' '}
							{t(`orderDetail.${expand ? 'collapse' : 'noCollapse'}`)}
						</div>
					</div>
					<TabLayout
						shipmentStatuses={statuses}
						products={products}
						currency={currency}
						currencyMarketPalace={shipment?.currency}
						transactions={transactions}
						loading={loading}
						packages={packages}
						loadingPackages={loadingPackages}
						claims={claims}
						loadingClaim={loadingClaims}
						shipment={shipment}
						refetch={fetchDetails}
						productUpdatable={productUpdatable}
						createAbleWaybill={createAbleWaybill}
					/>
				</div>
				<div className='shipment-details__milestone'>
					<span className='heading'>{!loading ? t('shipment.milestoneHeading') : <Skeleton width={150} />}</span>
					<TrackOrder
						statuses={statuses}
						shipment={shipment}
						handleTrackOrder={handleTrackOrder}
						milestones={milestones}
						loading={loading}
					/>
				</div>
			</div>
		</MainLayout>
	)
}

export default Shipment