import { Button, Tooltip } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { DATA_TABLE_LAYOUT, DATA_TABLE_LIST } from '../../../core/config'
interface HeaderListProps {
	total: number
	typeDataTable: string
	setTypeDataTable: (val: string) => void
}
const HeaderList: React.FC<HeaderListProps> = (props) => {
	const { total, typeDataTable, setTypeDataTable } = props
	const navigate = useNavigate()
    const {t} = useTranslation()
	return (
		<div className='flex justify-content-between'>
			<div className='dpl-flex align-items-center'>
				<h3 className='robotomedium mg-0 fsz-16 line-h-24 txt-color-black mg-r-4'>{t('shipments.list')}</h3>
				<span
					className={`${
						total > 0 ? 'bg-color-primary txt-color-black' : 'bg-color-gray-badge txt-color-gray4'
					} border-radius20  fsz-12 line-h-20 px-8 mg-r-20`}>
					{total}
				</span>
				<Tooltip
					title={<span className='robotoregular fsz-12 txt-color-white line-h-20'>{t('shipments.listLayout')}</span>}
					color='#FBB739'>
					<span
						className={`${
							typeDataTable === DATA_TABLE_LIST ? 'rad-4 bg-color-pink2' : ''
						} cursor-pointer mg-r-10 line-h-16  flex justify-content-center align-items-center w-26 h-26 rad-4`}>
						<i
							className={`${
								typeDataTable === DATA_TABLE_LIST ? 'txt-color-orange3' : ''
							} fsz-16 line-h-16  fa-light  fa-table-cells cursor-pointer txt-color-gray2`}
							onClick={() => setTypeDataTable(DATA_TABLE_LIST)}></i>
					</span>
				</Tooltip>
				<Tooltip
					title={<span className='robotoregular fsz-12 txt-color-white line-h-20'>{t('shipments.tableLayout')}</span>}
					color='#FBB739'>
					<span
						className={`${
							typeDataTable === DATA_TABLE_LAYOUT ? ' bg-color-pink2' : ''
						} w-26 h-26 cursor-pointer line-h-16  justify-content-center align-items-center flex justify-content-center align-items-center rad-4`}>
						<i
							className={`fsz-16 fa-light  fa-table-list cursor-pointer txt-color-gray2 ${
								typeDataTable === DATA_TABLE_LAYOUT ? 'txt-color-orange3' : ''
							}`}
							onClick={() => setTypeDataTable(DATA_TABLE_LAYOUT)}></i>
					</span>
				</Tooltip>
			</div>
			<Button
				className='btn-df btn-df-secondary-bg-white fsz-14 line-h-22 robotomedium flex align-items-center flex-nowrap'
				icon={<i className='fa-regular fa-plus mg-r-10'></i>}
				onClick={() => navigate('/shipments/create', { replace: true })}>
				{t('shipments.createShipments')}
			</Button>
		</div>
	)
}

export default HeaderList