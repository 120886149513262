import { Col, Form, Input, InputNumber, Row, Switch } from 'antd'
import { FormInstance, useWatch } from 'antd/lib/form/Form'
import { AxiosResponse } from 'axios'
import React, { FC, ReactNode, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { EditProductForSellFormNames } from '../../Interface/ProductInterface'
import { TransportService } from '../../Interface/StoredProductInterface'
import { warehouseRepository } from '../../repositories/WarehouseRepository'
import M24Notification from '../../utils/M24Notification'
import { InputNumberWithBeforeAddon } from '../base/InputNumberWithBeforeAddon'
import { InputMoneySeparatorType } from '../InputMoney'
import { formatter } from '../InputMoney/utils'
import { onPressOnlyNumberAndAllowDecimals } from './helpers'
import { RowItem } from './RowItem'

interface TransportSectionProps {
	form: FormInstance<Record<EditProductForSellFormNames, any>>
	integration: any
	setIsDisableSubmitButton: (isDisable: boolean) => void
	logisticsFromData: any
	transportServices: any
	handleTransportServices: any
}

const TransportSection: FC<TransportSectionProps> = React.memo(
	({ form, integration, setIsDisableSubmitButton, handleTransportServices, transportServices }: TransportSectionProps) => {
		const { t } = useTranslation()

		const logistics: any = useWatch('logistics', form)

		const onChangeLogistic = (value: boolean, id: number) => {
			logistics[id] = value
			form.setFieldsValue({
				logistics,
			})
			setIsDisableSubmitButton(false)
		}

		useEffect((): void => {
			if (!integration) {
				return
			}

			warehouseRepository
				.getTransportServices(integration)
				.then((res: AxiosResponse<TransportService[]>): void => {
					handleTransportServices(res.data.filter(x => x.enabled))
				})
				.catch(() => {
					M24Notification.notifyError(t('message.titleFailed'), t(`productForSell.get-logistic-error`), '', 4)
				})

			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [integration])

		const getInitialTransportService = (logisticId: number | string) => {
			if (!logistics) {
				return true
			}
			return logistics[logisticId]
		}

		return (
			<section id='transport-section'>
				<h2 className='section-header'>
					<span>{t('productForSell.delivering-detail-box')}</span>
				</h2>

				<RowItem label={t('productForSell.item-weight')} isRequire={true}>
					<Form.Item name='weight' rules={[{ required: true, message: t('productForSell.weight-validate') }]}>
						<InputNumberWithBeforeAddon
							placeholder={t('productForSell.weight')}
							className='font-medium w-100-percent'
							addonAfter={t('productForSell.kg')}
							min={0}
							max={1000}
							onKeyPress={onPressOnlyNumberAndAllowDecimals}
						/>
					</Form.Item>
				</RowItem>

				<RowItem label={t('productForSell.item-sizes')} className='size-inputs mgt10' isRequire={true}>
					<Row gutter={12}>
						<Col span={8}>
							<Form.Item
								name='length'
								rules={[
									{
										required: true,
										message: t('productForSell.length-validate'),
									},
								]}>
								<InputNumber
									placeholder={t('productForSell.item-length')}
									className='font-medium w-100-percent'
									min={0}
									max={1000000}
									onKeyPress={onPressOnlyNumberAndAllowDecimals}
								/>
							</Form.Item>
						</Col>

						<Col span={8}>
							<Form.Item
								name='width'
								rules={[
									{
										required: true,
										message: t('productForSell.width-validate'),
									},
								]}>
								<InputNumber
									placeholder={t('productForSell.item-wide')}
									className='m24-input font-medium w-100-percent'
									min={0}
									max={1000000}
									onKeyPress={onPressOnlyNumberAndAllowDecimals}
								/>
							</Form.Item>
						</Col>

						<Col span={8}>
							<Form.Item
								name='height'
								rules={[
									{
										required: true,
										message: t('productForSell.height-validate'),
									},
								]}>
								<InputNumber
									placeholder={t('productForSell.item-height')}
									className='m24-input font-medium w-100-percent'
									min={0}
									max={1000000}
									onKeyPress={onPressOnlyNumberAndAllowDecimals}
								/>
							</Form.Item>
						</Col>
					</Row>
					<div className='size-unit'>{t('productForSell.cm-unit')}</div>
				</RowItem>
				{transportServices.length > 0 &&
				<RowItem label={transportServices.length > 0 ? t('productForSell.transport-price') : false} isRequire={true}>
					
						{transportServices.map(
							(transportService: TransportService, index: number): ReactNode => (
								<div className='transport-service' key={`transport-service-${index}`}>
									<span className='txt-size-h7 '>
										{transportService.logisticName}
										<span className='robotoregular mg-l-8'>
											(
											{t('productForSell.transport-max', {
												value: formatter(
													String(transportService.weightLimits.item_max_weight * 1000),
													undefined,
													InputMoneySeparatorType.dot,
													undefined
												),
											})}
											)
										</span>
									</span>

									<Form.Item
										name={['logistics', String(transportService.logisticId)]}
										hidden
										className='input-hidden'
										>
										<Input />
									</Form.Item>
									{logistics && (
										<Switch
											style={{ color: '#3340B6' }}
											checked={getInitialTransportService(transportService.logisticId)}
											onChange={(value) => onChangeLogistic(value, transportService.logisticId)}
										/>
									)}
								</div>
							)
						)}
				</RowItem>}
			</section>
		)
	}
)

export { TransportSection }
