import './styles.scss'

import { ReloadOutlined } from '@ant-design/icons'
import { Avatar, Button, Checkbox, Col, DatePicker, Divider, Input, Row, Select } from 'antd'
import lodash from 'lodash'
import moment from 'moment'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { handleChangeRangeDate, handleInputChange, handleInputChangeMany } from '../../../utils/StringUtils'

const { RangePicker } = DatePicker
type OrderFilterProps = {
	statuses: any
	marketplaces: any
	providers: any
	accounts: any
	filter: any
	setFilter: Dispatch<SetStateAction<any>>
	getData: (filter?: any) => void
}

function OrderFilter(props: OrderFilterProps) {
	const { t } = useTranslation()
	const { statuses, marketplaces, accounts, filter, setFilter, getData } = props
	const [showFilter, setShowFilter] = useState<boolean>(true)
	const [isExpand, setExpand] = useState<boolean>(false)
	const [accountSelected, setAccountSelected] = useState<any>()

	useEffect(() => {
		if (accounts && accounts.length > 0 && filter && filter.providerUsername && filter.provider) {
			let account = accounts.find(
				(x: any) => x.name === filter.providerUsername && x.provider.code === filter.provider
			)
			if (account) {
				setAccountSelected(account)
			}
		}
	}, [accounts])

	const onChangeFilterMany = (key: string, isCheck: boolean, code: string) => {
		let temp = handleInputChangeMany(key, code, filter)
		setFilter(temp)
	}
	const onChange = (key: string, value: any) => {
		let temp = handleInputChange(key, value, filter)
		setFilter(temp)
	}
	const onChangeRangeDate = (date: any) => {
		let temp = handleChangeRangeDate(date, 'timestampFrom', 'timestampTo', filter)
		setFilter(temp)
	}
	const resetFilter = () => {
		setFilter({})
		setAccountSelected(null)
		getData()
	}
	const onChangeAccount = (key1: string, value1: any, key2: string, value2: any) => {
		let temp1 = handleInputChange(key1, value1, filter)
		let temp2 = handleInputChange(key2, value2, { ...temp1 })
		setFilter(temp2)
	}

	const handleEventsDown = (event: any) => {
		getData({ ...filter, page: 0 })
	}
	return (
		<div
			className={`border-card-header _filter-container pd-16 rad-6 ${!showFilter ? 'cursor-pointer' : ''}`}
			onClick={!showFilter ? () => setShowFilter(!showFilter) : undefined}
		>
			<Row
				className={`align-items-center cursor-pointer ${!showFilter ? 'mg-bt-0' : 'mg-bt-12'}`}
				onClick={() => setShowFilter(!showFilter)}
			>
				<span className={'fsz-16 robotomedium txt-color-black line-h-24'}>{t('orders.filterTitle')}</span>
				<Col flex={'auto'} className={'pdl8 pdr5'}>
					<Divider type={'horizontal'} className={'mg-0'} style={{ backgroundColor: '#EBEBEB' }} />
				</Col>
				<i
					className={`fas fa-chevron-${showFilter ? 'up' : 'down'} cursor-pointer pdl5  txt-color-black`}
					onClick={() => {
						setShowFilter(!showFilter)
						setExpand(false)
					}}
				/>
			</Row>
			{showFilter && (
				<>
					<Row className={'dpl-flex align-items-start mgbt12 flex-nowrap'} align={'middle'}>
						<span className='dpl-flex align-items-baseline flex-wrap'>
							<span className={'txt-size-h7 robotoregular txt-color-black mgr10'}>
								{t('orders.order_status')}:{' '}
							</span>
							{statuses.map((item: any) => {
								let isSelect = false
								if (filter.statuses) {
									let temp = filter.statuses.split(',')
									if (temp && temp.includes(item.code)) isSelect = true
								}
								return (
									<span
										className={isSelect ? 'status-package active' : 'status-package'}
										key={item.code}
										onClick={() => {
											onChangeFilterMany('statuses', !isSelect, item.code)
										}}
									>
										{item.name}
									</span>
								)
							})}
						</span>
					</Row>
					{isExpand && (
						<div>
							<Row justify={'start'} align={'middle'} className=''>
								<Col span={12} className='mg-bt-12'>
									<Row align={'middle'}>
										<Col span={6}>
											<span className={'txt-size-h7 robotoregular txt-color-black'}>
												{t('orders.order_code')}:
											</span>
										</Col>
										<Col span={16}>
											<Input
												value={lodash.get(filter, 'code', '')}
												placeholder={`${t('orders.order_code')}`}
												onChange={(event) => onChange('code', event.target.value)}
												onPressEnter={handleEventsDown}
											/>
										</Col>
									</Row>
								</Col>
								<Col span={12} className='mg-bt-12'>
									<Row justify={'start'} align={'middle'} className={''}>
										<Col span={6}>
											<span className={'txt-size-h7 robotoregular txt-color-black'}>
												{t('orders.order_times')}:
											</span>
										</Col>
										<Col span={18}>
											<RangePicker
												format={'DD-MM-YYYY'}
												value={[
													filter.timestampFrom ? moment(filter.timestampFrom) : null,
													filter.timestampTo ? moment(filter.timestampTo) : null,
												]}
												placeholder={[`${t('orders.from_dateTime')}`, `${t('orders.to_dateTime')}`]}
												onChange={(value: any, dateString: any) => {
													onChangeRangeDate(value)
												}}
												className={'width100'}
											/>
										</Col>
									</Row>
								</Col>
							</Row>
							<Row align={'middle'} className={'mgt5'} justify={'space-between'}>
								<Col span={12}>
									<Row justify={'start'} align={'middle'}>
										<Col span={6}>
											<span className={'txt-size-h7 robotoregular txt-color-black'}>
												{t('orders.provider_domain')}:
											</span>
										</Col>
										<Col span={16} className={''}>
											<Select
												placeholder={t('orders.provider_domain')}
												className={'width100'}
												value={lodash.get(accountSelected, 'id', undefined)}
												onChange={(value) => {
													let account = accounts.find((x: any) => x.id === value)
													if (account) {
														setAccountSelected(account)
														onChangeAccount(
															'providerUsername',
															account.name,
															'provider',
															account.provider.code
														)
													}
												}}
											>
												{accounts.map((x: any) => {
													return (
														<Select.Option value={x.id} className={''}>
															<Row className={'dpl-flex align-items-center'}>
																<Avatar
																	size={20}
																	src={lodash.get(x, 'provider.logo', '')}
																	shape={'circle'}
																	className='bd-none-impt'
																></Avatar>
																<span className={'mgl5 robotoregular txt-size-h8'}>
																	{lodash.get(x, 'domain', '')}
																</span>
																<Divider
																	type={'vertical'}
																	className={'bd-color-black'}
																/>
																<span className={'robotoregular txt-size-h8'}>
																	{lodash.get(x, 'name', '')}
																</span>
															</Row>
														</Select.Option>
													)
												})}
											</Select>
										</Col>
									</Row>
								</Col>
								<Col span={12}>
									<Row align={'middle'} className={''}>
										<Col span={6}>
											<span className={'txt-size-h7 robotoregular txt-color-black'}>
												{t('orders.findBy_marketPlace')}:
											</span>
										</Col>
										<Col className={''} span={16}>
											<Row>
												{marketplaces.map((x: any) => {
													let isSelect = false
													if (filter.marketplaces) {
														let temp = filter.marketplaces.split(',')
														if (temp && temp.includes(x.code)) isSelect = true
													}
													return (
														<Checkbox
															key={x.code}
															value={x.code}
															checked={isSelect}
															onChange={(e) => {
																onChangeFilterMany(
																	'marketplaces',
																	!isSelect,
																	e.target.value
																)
															}}
															className={'_status-checkbox'}
														>
															<span
																className={
																	'txt-size-h7 robotoregular txt-capitalize txt-color-black'
																}
															>
																{x.name}
															</span>
														</Checkbox>
													)
												})}
											</Row>
										</Col>
									</Row>
								</Col>
							</Row>
						</div>
					)}
					<Row className={'align-items-center justify-content-between'}>
						<Col>
							<span
								onClick={() => {
									setExpand(!isExpand)
								}}
								className={'cursor-pointer robotoregular txt-color-black'}
							>
								<i className={`fas fa-chevron-${isExpand ? 'up' : 'down'} mgr5 fsz-12`}></i>
								{t(!isExpand ? 'orders.filterExpand' : 'orders.filterCollapse')}
							</span>
						</Col>
						<Col>
							{!lodash.isEmpty(filter) && (
								<span
									onClick={resetFilter}
									className={
										'mgr20 cursor-pointer fsz-12 line-h-20 robotoregular txt-color-gray refresh-filter-btn'
									}
								>
									<ReloadOutlined className={''} /> &nbsp;
									{t('filter.refresh')}
								</span>
							)}
							<Button
								className={'btn-df btn-df-secondary-bg-lemon px-24 py-5 fsz-14 line-h-22 '}
								ghost
								onClick={() => getData({ ...filter, page: 0 })}
							>
								<span className={'robotomedium txt-capitalize '}>{t('orders.button_search')}</span>
							</Button>
						</Col>
					</Row>
				</>
			)}
		</div>
	)
}

export default OrderFilter

