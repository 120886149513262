import React, { useState } from 'react'
import { Breadcrumb } from 'antd'
import { breadCrumbsNameFromRoutes, exceptionRoutes } from '../../../core/config'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const { Item } = Breadcrumb
interface BreadcrumbCustomProps {
	breadcrumbs: any
}

const M24Breadcrumb: React.FC<BreadcrumbCustomProps> = ({ breadcrumbs }) => {
	const { t } = useTranslation()
	const location = useLocation()
	const [searchParams] = useSearchParams()
	const [pathName] = useState(location.pathname.split('/'))
	const { code, claimCode, back, orderCode } = useParams()
	const totalRendered =
		breadcrumbs?.total > 0 ? (
			<span className={`bg-color-primary txt-color-white border-radius20  fsz-12 line-h-20 px-8`}>
				{breadcrumbs?.total}
			</span>
		) : breadcrumbs?.total === 0 ? (
			<span className='bg-color-gray-badge txt-color-gray4 border-radius20  fsz-12 line-h-20 px-8'>
				{breadcrumbs?.total}
			</span>
		) : null

	return (
		<div className='flex flex-col'>
			{/* ăn theo pathName */}
			<Breadcrumb separator={<i className='fa-solid fa-hyphen fsz-14 line-h-22'></i>}>
				{!exceptionRoutes.includes(pathName[1]) && pathName[1] && !claimCode && !searchParams.get('orderCode') && (
					<>
						<Item>
							<span
								className='cursor-pointer'
								onClick={() => (window.location.href = `/#/${pathName[1]}`)}
							>
								{t(`breadcrumbs.${pathName[1]}CurrentPage`)}
							</span>
						</Item>
						{!breadcrumbs?.currentStep &&
							pathName
								.filter((path) => path)
								.map((item, idx) => {
									const linkItem = pathName
										.slice(0, idx-1)
										.map((path: string) => (path.startsWith('create') ? 'create' : path))
										.join('/')

									return (
										<Item key={linkItem}>
											{item !== '' && (
												<Link to={linkItem}>
													{/* {console.log('pathname',pathName[idx])} */}
													{breadCrumbsNameFromRoutes.includes(item)
														? t(
																`breadcrumbs.${
																	item.startsWith('create')
																		? `${item}-${pathName[idx]}`
																		: item
																}`
														  )
														: (pathName[idx - 1] === 'orders' ||
																pathName[idx - 1] === 'claims' ||
																pathName[idx - 1]) &&
														  item === code
														? `${t(`breadcrumbs.${pathName[idx - 1]}Details`)} #${item}`
														: item}
												</Link>
											)}
										</Item>
									)
								})}
						{totalRendered}
					</>
				)}

				{breadcrumbs?.currentStep && <Item>{t(`breadcrumbs.cart`)}</Item>}
				{breadcrumbs?.curTab && <Item>{t(`breadcrumbs.${breadcrumbs?.curTab}`)}</Item>}
			</Breadcrumb>
			{/* logic hiện tại ở trên : bắt theo các pathName của location - (dài nhất là route nào có child như sửa/hoặc tạo được điều hướng từ route khác) */}
			{pathName[1] === 'claims' && claimCode && (
				<Breadcrumb separator={<i className='fa-solid fa-hyphen fsz-14 line-h-22'></i>}>
					<Item>
						<span className='cursor-pointer' onClick={() => (window.location.href = `/#/${pathName[1]}`)}>
							{t(`breadcrumbs.${pathName[1]}CurrentPage`)}
						</span>
					</Item>
					{back === 'claims' && (
						<Item>
							<Link className='cursor-pointer' to={'/claims'}>
								{t(`breadcrumbs.claims`)}
							</Link>
						</Item>
					)}

					{back !== 'claims' && (
						<>
							<Item>
								<Link className='cursor-pointer' to={`/${back}s`}>
									{t(`breadcrumbs.${back}s`)}
								</Link>
							</Item>
							<Item>
								<Link className='cursor-pointer' to={`/${back}s/${orderCode}`}>
									{`${t(`breadcrumbs.detailDesc`, { value: `#${orderCode}` })} `}
								</Link>
							</Item>
						</>
					)}
					<Item>{`${t(`breadcrumbs.claimsDetails`)} #${claimCode}`}</Item>
				</Breadcrumb>
			)}
			{pathName[1] === 'claims' && searchParams.get('orderCode') && (
				<Breadcrumb separator={<i className='fa-solid fa-hyphen fsz-14 line-h-22'></i>}>
					<Item>
						<span className='cursor-pointer' onClick={() => (window.location.href = `/#/${pathName[1]}`)}>
							{t(`breadcrumbs.ordersCurrentPage`)}
						</span>
					</Item>
					<Item>
						<Link className='cursor-pointer' to={`/${searchParams.get('back')?.split('/')[1]}`}>
							{t(`breadcrumbs.orders`)}
						</Link>
					</Item>
					<Item>
						<Link className='cursor-pointer' to={`${searchParams.get('back')}`}>
							{t(`breadcrumbs.detailDesc`, { value: searchParams.get('orderCode') })}
						</Link>
					</Item>
					<Item>{`${t(`breadcrumbs.create-claims`)}`}</Item>
				</Breadcrumb>
			)}

			{pathName[1] === 'warehouse' && pathName[2] === 'stored-product' && pathName[4] === 'edit' && (
				<Breadcrumb separator={<i className='fa-solid fa-hyphen fsz-14 line-h-22'></i>}>
					<Item>
						<span className='cursor-pointer'>{t(`breadcrumbs.${pathName[1]}CurrentPage`)}</span>
					</Item>
					<Item>
						<Link className='cursor-pointer' to={'/warehouse/stored-products'}>
							{t('breadcrumbs.stored-products')}
						</Link>
					</Item>
					<Item>{t('breadcrumbs.edit')}</Item>
				</Breadcrumb>
			)}

			{pathName[1] === 'warehouse' && pathName[2] === 'stored-products' && (
				<div className='flex align-items-center'>
					<Breadcrumb separator={<i className='fa-solid fa-hyphen fsz-14 line-h-22'></i>}>
						<Item>
							<span
								className='cursor-pointer'
								onClick={() => (window.location.href = `/#/${pathName[1]}/stored-products`)}
							>
								{t(`breadcrumbs.${pathName[1]}CurrentPage`)}
							</span>
						</Item>
						<Item>
							<Link className='cursor-pointer' to={'/warehouse/stored-products'}>
								{t('breadcrumbs.stored-products')}
							</Link>
						</Item>
					</Breadcrumb>
					{totalRendered}
				</div>
			)}

			{pathName[1] === 'pending-products' && pathName[2] === 'shopee' && pathName[4] === 'edit' && (
				<div className='flex align-items-center'>
					<Breadcrumb separator={<i className='fa-solid fa-hyphen fsz-14 line-h-22'></i>}>
						<Item>
							<span className='cursor-pointer txt-capitalize'>
								{t(`breadcrumbs.${pathName[1]}CurrentPage`)}
							</span>
						</Item>
						<Item>
							<Link className='cursor-pointer' to={'/pending-products/shopee'}>
								{t('breadcrumbs.pending-products')}
							</Link>
						</Item>
						<Item>{t('breadcrumbs.edit')}</Item>
					</Breadcrumb>
					{totalRendered}
				</div>
			)}

			{pathName[1] === 'selling-products' && pathName[2] === 'shopee' && pathName[4] === 'edit' && (
				<div className='flex align-items-center'>
					<Breadcrumb separator={<i className='fa-solid fa-hyphen fsz-14 line-h-22'></i>}>
						<Item>
							<span className='cursor-pointer txt-capitalize'>
								{t(`breadcrumbs.${pathName[1]}CurrentPage`)}
							</span>
						</Item>
						<Item>
							<Link className='cursor-pointer' to={'/selling-products/shopee'}>
								{t('breadcrumbs.selling-products')}
							</Link>
						</Item>
						<Item>{t('breadcrumbs.edit')}</Item>
					</Breadcrumb>
					{totalRendered}
				</div>
			)}

			{pathName[1] === 'pending-products' && pathName[2] === 'shopee' && !pathName[4] && (
				<div className='flex align-items-center'>
					<Breadcrumb separator={<i className='fa-solid fa-hyphen fsz-14 line-h-22'></i>}>
						<Item>
							<span className='cursor-pointer txt-capitalize'>Sàn thương mại liên kết</span>
						</Item>
						<Item>
							<Link className='cursor-pointer' to={'/pending-products/shopee'}>
								{t('breadcrumbs.pending-products')}
							</Link>
						</Item>
					</Breadcrumb>
					{totalRendered}
				</div>
			)}

			{pathName[1] === 'selling-products' && pathName[2] === 'shopee' && !pathName[4] && (
				<div className='flex align-items-center'>
					<Breadcrumb separator={<i className='fa-solid fa-hyphen fsz-14 line-h-22'></i>}>
						<Item>
							<span className='cursor-pointer txt-capitalize'>Sàn thương mại liên kết</span>
						</Item>
						<Item>
							<Link className='cursor-pointer txt-capitalize' to={'/selling-products/shopee'}>
								{t('breadcrumbs.selling-products')}
							</Link>
						</Item>
					</Breadcrumb>
					{totalRendered}
				</div>
			)}
		</div>
	)
}

export default M24Breadcrumb

