import React, { useState } from 'react'

import M24Pagination from '../../../components/Pagination/M24Pagination'
import { DATA_TABLE_LAYOUT, DATA_TABLE_LIST } from '../../../core/config'
import { ProviderInterface } from '../../../Interface/AccountBalanceInterface'
import { ConnectionInterface } from '../../../Interface/ConnectionInterface'
import MarketPalaceInterface from '../../../Interface/MarketPalaceInterface'
import ShipmentsFilterInterface from '../../../Interface/ShipmentFilterInterface'
import ShipmentInterface from '../../../Interface/ShipmentInterface'
import ShipmentStatusInterface from '../../../Interface/ShipmentStatusInterface'
import { getCleanFormFilter } from '../../../utils/StringUtils'
import DataTable from './DataTable'
import ShipmentList from './List'

interface ShipmentItemsProps {
	data: ShipmentInterface[]
	accounts: ConnectionInterface[]
	statuses: ShipmentStatusInterface[]
	loading: boolean
	page: number
	size: number
	total: number
	isSkeleton: boolean
	filter: ShipmentsFilterInterface | {}
	marketPalaces: MarketPalaceInterface[]
	providers: ProviderInterface[]
	getData: () => void
	handleFilter: (params: ShipmentsFilterInterface | {}) => void
}

const ShipmentItems: React.FC<ShipmentItemsProps> = (props) => {
	const { data, accounts, statuses, loading, filter, page, size, total, handleFilter, providers, isSkeleton } = props

	const [typeDataTable, setTypeDataTable] = useState(DATA_TABLE_LIST)

	const pageChangeHandler = (filter: any) => {
		const { page, size } = filter
		handleFilter(
			getCleanFormFilter(
				{
					...filter,
					page: (page - 1).toString(),
					size: size.toString(),
				},
				[],
				['pageSize']
			)
		)
	}

	return (
		<div>
			{typeDataTable === DATA_TABLE_LIST && (
				<ShipmentList
					typeDataTable={typeDataTable}
					isSkeleton={isSkeleton}
					handleTypeTableData={(val: string) => setTypeDataTable(val)}
					total={total}
					filter={filter}
					accounts={accounts}
					statuses={statuses}
					providers={providers}
					data={data}
					loading={loading}
				/>
			)}
			{typeDataTable === DATA_TABLE_LAYOUT && (
				<DataTable
					typeDataTable={typeDataTable}
					isSkeleton={isSkeleton}
					handleTypeTableData={(val: string) => setTypeDataTable(val)}
					total={total}
					filter={filter}
					accounts={accounts}
					statuses={statuses}
					providers={providers}
					data={data}
					loading={loading}
				/>
			)}
			<div className='flex justify-content-end'>
				{data && data.length > 0 && (
					<M24Pagination
						onPageChange={pageChangeHandler}
						filter={{ ...filter, page, size }}
						pagination={{
							current: page,
							pageSize: size,
							hideOnSinglePage: true,
							total,
						}}
						className='mg-t-24 mg-bt-24'
					/>
				)}
			</div>
		</div>
	)
}

export default ShipmentItems