import ReactDOM from "react-dom"
import "../node_modules/antd/dist/antd.less"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import "./resources/localizations/i18n"
import "./resources/styles/custom.scss"

// Sentry.init({
//     dsn: "https://01073d81c89845f5be15645f8274d1c6@o928949.ingest.sentry.io/6055378",
//     integrations: [new Integrations.BrowserTracing()],

//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 1.0,
// });

ReactDOM.render(
    // <React.StrictMode>
    <App />,
    // </React.StrictMode>,
    document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
