import { PlusOutlined } from '@ant-design/icons'
import { Cascader, Form, FormInstance, Input } from 'antd'
import { UploadFile } from 'antd/es/upload/interface'
import { AxiosResponse } from 'axios'
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import RichTextEditor from '../../components/RichTextEditor'
import UploadCommon from '../../components/UploadCommon'
import { EditProductForSellFormNames } from '../../Interface/ProductInterface'
import { connectMarketRepository } from '../../repositories/ConnectMarketRepository'
import { uploadRepository } from '../../repositories/UploadRepository'
import M24Notification from '../../utils/M24Notification'
import { searchTree } from './helpers'
import { RowItem } from './RowItem'

interface CommonInfoSectionProps {
	form: FormInstance<Record<EditProductForSellFormNames, any>>
	productImages: UploadFile<any>[]
	integration: any
	categoryId?: number
	productVideo: string
	setProductVideo: (url: string) => void
	setProductImages: (images: UploadFile<any>[]) => void
	setIsDisableSubmitButton: (value: boolean) => void
	setCategoryId: (id: number | undefined) => void
	loading: boolean
	isEdit: boolean
}

export interface CommonInfoData {
	detail: string
}

const CommonInfoSection = forwardRef(
	(
		{
			form,
			productImages = [],
			integration,
			productVideo,
			setProductVideo,
			setProductImages,
			setIsDisableSubmitButton,
			categoryId,
			setCategoryId,
			loading,
			isEdit
		}: CommonInfoSectionProps,
		ref: any
	) => {
		const { t } = useTranslation()
		const [categories, setCategories] = useState<any[]>([])
		const [hasError, setHasError] = useState<null | boolean>(null)
		

		useEffect(() => {
			if (!integration) {
				return
			}

			connectMarketRepository
				.getCategories(integration)
				.then((res: AxiosResponse<any>) => {
					setCategories(res.data)

					var result = searchTree(categoryId, res.data)
					form.setFieldsValue({
						category: result,
					})
				})
				.catch(() => {
					M24Notification.notifyError(t('message.titleFailed'), t(`productForSell.get-category-error`), '', 5)
				})
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [integration])

		const longDescriptionRef: any = useRef()

		const handleSetProductImages = (images: any[]): void => {
			setProductImages(images)
			setIsDisableSubmitButton(false)
		}

		useEffect(() => {
			setHasError(!!productImages.find((image: any) => image.status === 'error' ))
			return () => {
				setHasError(null)
			}
		}, [productImages])

		const uploadFile = ({ file }: { file: File }) => {
			uploadRepository
				.create(file)
				.then((res: AxiosResponse<string>): void => {
					handleSetProductImages([
						...productImages,
						{
							uid: String(productImages.length),
							name: file.name,
							url: res.data,
							// status: 'done',
							isSquare: true,
						},
					])
				})
				.catch(() => {
					M24Notification.notifyError(t('message.titleFailed'), t(`message.try_again`), '', 4)
				})

			return false
		}

		const onChangeCategory = (values: any[]) => {
			if (!values) {
				setCategoryId(undefined)
			} else {
				const category: number | undefined = values[values.length - 1]
				setCategoryId(category)
			}

			form.resetFields(['attributes'])
		}

		useImperativeHandle(ref, () => ({
			getCommonInfoValues: (): CommonInfoData => {
				return {
					detail: longDescriptionRef.current?.getContent(),
				}
			},
		}))

		const handleChange = ({fileList}: any) => {
			const fileListUpdated = fileList.map((file: any) => ({
				...file,
				response: false,
			}))
			 setProductImages(fileListUpdated)
		}

		return (
			<section id='common-info-section'>
				<h2 className='section-header'>
					<span>{t('productForSell.general-info-box')}</span>
				</h2>
				<RowItem label={`${t('productForSell.shop-name')}`} isRequire={true}>
					<Form.Item name='integrationName'>
						<Input className='m24-input font-medium' disabled />
					</Form.Item>
				</RowItem>

				<RowItem label={`${t('productForSell.item-name')}`} isRequire={true}>
					<Form.Item
						name='name'
						rules={[
							{ required: true, message: `${t('productForSell.name-validate')}` },
							{ min: 3, message: `${t('productForSell.name-validate-length')}` },
							{ max: 100, message: `${t('productForSell.name-validate-length')}` },
						]}>
						<Input
							showCount
							minLength={3}
							maxLength={100}
							className='m24-input font-medium'
							placeholder={`${t('productForSell.item-name-placeholder')}`}
						/>
					</Form.Item>
				</RowItem>

				<RowItem label={`${t('productForSell.select-category')}`} isRequire={true}>
					<Form.Item
						name='category'
						rules={[
							{
								required: true,
								message: `${t('productForSell.category-validate')}`,
							},
						]}>
						<Cascader
							options={categories}
							className='m24-select txt-size-h7'
							onChange={onChangeCategory}
							fieldNames={{
								label: 'categoryName',
								value: 'categoryId',
								children: 'childrens',
							}}
							dropdownClassName='dropdown-popup-custom'
							showSearch
							placeholder={`${t('productForSell.select-category')}`}
						/>
					</Form.Item>
				</RowItem>

				<RowItem label={`${t('productForSell.product-sku')}`}>
					<Form.Item name='code'>
						<Input
							maxLength={300}
							className='m24-input'
							placeholder={`${t('productForSell.product-sku-placeholder')}`}
						/>
					</Form.Item>
				</RowItem>

				<RowItem label={`${t('productForSell.item-image')}`} isRequire={true}>
					<Form.Item name='productImageMock' className='input-hidden'>
						<Input />
					</Form.Item>
					<UploadCommon
						listType='picture-card'
						fileList={productImages}
						isDrag={true}
						accept='image/*'
						isEdit={isEdit}
						setFileList={handleSetProductImages}
						customRequest={(file: any) => uploadFile(file)}
						onChange={handleChange}
						maxCount={9}
						maxSize={2}
						require={true}
						handleError={(val:boolean) => setHasError(val)}
						requireMessage={`${t('productForSell.product-image-validate')}`}
						fileConfig={{ isSquare: true, minResolution: 500 }}>
						{productImages.length < 9 && <PlusOutlined className='plus-icon' style={{ fontSize: 22 }} />}
					</UploadCommon>
					{hasError && <span className='txt-color-red2'>{t('editPendingProduct.imagesSquareRequired')}</span>}
				</RowItem>
				<RowItem label={`${t('productForSell.item-long-description')}`} isRequire={true}>
					<RichTextEditor
						ref={longDescriptionRef}
						placeholder={`${t('productForSell.fill-description')}`}
						onEditorChange={() => {
							setIsDisableSubmitButton(false)
						}}
						isRequired={true}
						form={form}
						formItemName='detail'
						requireMessage={`${t('productForSell.description-validate')}`}
					/>
				</RowItem>
			</section>
		)
	}
)

export { CommonInfoSection }

