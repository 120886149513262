import React, { useMemo } from 'react'
import { Modal, Upload, UploadProps } from 'antd'
import { useState } from 'react'
import { DragableUpload } from './DrapableUpload'
import { RcFile, UploadFile } from 'antd/es/upload/interface'

import './style.scss'
import { useTranslation } from 'react-i18next'
import M24Notification from '../../utils/M24Notification'
import { getBase64 } from '../../utils/util'

export enum ImageTypesEnum {
	JPG = 'image/jpg',
	JPEG = 'image/jpeg',
	PNG = 'image/png',
	MP4 = 'video/mp4',
}

export interface ImageConfigInterface {
	minWidth?: number
	minHeight?: number
	isSquare?: boolean
	minResolution?: number // kích thước độ phân giải tối thiếu (width và height) (Dùng trong trường hợp cần ảnh là hình vuông)
}

export interface UploadCommonProps extends UploadProps {
	isDrag?: boolean
	children?: any
	maxSize?: number
	imageType?: ImageTypesEnum[]
	mediaType?: 'image' | 'video'
	require?: boolean
	requireMessage?: string
	videoUrlForPreview?: string
	titleModalPreview?: string
	fileConfig?: ImageConfigInterface
	setFileList?: (images:any) => void
	handleChange?: (file: any) => void
	handleError?: (val: boolean) => void 
	isEdit?: boolean
}

const UploadCommon = React.memo(
	({
		isDrag = false,
		maxSize = 10,
		mediaType = 'image',
		require = false,
		videoUrlForPreview,
		requireMessage,
		titleModalPreview,
		handleError,
		isEdit,
		fileConfig = {},
		...props
	}: UploadCommonProps): JSX.Element => {
		const [isShowPreviewFile, setIsShowPreviewFile] = useState<boolean>(false)
		const [previewFile, setPreviewFile] = useState<string>()
		const { t } = useTranslation()
		const [demesion, setDemesion] = useState<any>();
		

		const imageType =
			mediaType === 'image' ? [ImageTypesEnum.JPG, ImageTypesEnum.JPEG, ImageTypesEnum.PNG] : [ImageTypesEnum.MP4]

		const handlePreviewFile = async (file: any) => {
			if (!file.url && !file.preview) {
				file.preview = await getBase64(file.originFileObj)
			}
			setPreviewFile(file.url || file.preview)
			setIsShowPreviewFile(true)
		}

		const beforeUpload = (file: RcFile): boolean | undefined => {
			const type: any = file.type
			const isFileTypeValid = imageType.includes(type)

			if (imageType.length > 0 && !isFileTypeValid) {
				M24Notification.notifyError(t('message.titleFailed'), t('message.file_not_image_file'), '', 2)

				return false
			}

			const isLessThanMaxSize = file.size / 1024 / 1024 < 10

			if (!isLessThanMaxSize) {
				M24Notification.notifyError(t('message.titleFailed'), t('shipment.fileSize'), '', 2)
				return false
			}

			const options: any = { file }

			if (fileConfig.isSquare || fileConfig.minResolution) {
				const { minResolution } = fileConfig
				const reader = new FileReader()
				reader.readAsDataURL(file)
				reader.onload = function (e: any) {
					const image: any = new Image()
					image.src = e.target.result
					image.onload = function () {
						const height = this.height
						const width = this.width
						if (height !== width) {
							M24Notification.notifyError(
								t('message.titleFailed'),
								t('productForSell.validate-upload-square'),
								'',
								4
							)
							// if(handleError) {
							// 	handleError(true)
							// }
							if(isEdit) {
								const fileListUpdated = props.fileList?.map(image => {
									if(image.uid === file.uid) {
										
										return {...image, status: 'error',
										response: false}
									}else{
										return image
									}
								})
								if(props.setFileList) {
									props.setFileList(fileListUpdated)
								}
							}
							return false
						} else if (width < Number(minResolution)) {
							M24Notification.notifyError(
								t('message.titleFailed'),
								t('productForSell.validate-upload-min-resolution', {
									minResolution,
								}),
								'',
								4
							)
							if(isEdit) {
								const fileListUpdated = props.fileList?.map(image => {
									if(image.uid === file.uid) {
										
										return {...image, status: 'error',
										response: false}
									}else{
										return image
									}
								})
								if(props.setFileList) {
									props.setFileList(fileListUpdated)
								}
							}
							
							// if(handleError) {
							// 	handleError(true)
							// }
							return false
						}

						props.customRequest && props.customRequest(options)
						return true
					}
				}
			} else {
				props.customRequest && props.customRequest(options)
			}
			return false
		}

		const isValidateFailed = useMemo((): boolean => {
			return require && Number(props.fileList?.length) < 1
		}, [props.fileList?.length, require])

		const UploadComponent = isDrag ? DragableUpload : Upload

		return (
			<>
				<UploadComponent
					onPreview={handlePreviewFile}
					{...props}
					beforeUpload={beforeUpload}
					className={`upload-common ${props.className || ''} ${
						isValidateFailed && 'upload-common-validate-error'
					}`}
				/>
				{isValidateFailed && (
					<div role='alert' className='upload-error-message ant-form-item-explain-error'>
						{requireMessage || t('message.field-required')}
					</div>
				)}

				<Modal
					open={isShowPreviewFile}
					footer={false}
					onCancel={() => {
						setIsShowPreviewFile(false)
						setPreviewFile('')
					}}
					wrapClassName='upload-preview-modal'
					closeIcon={<i className='fa-solid fa-xmark'></i>}
					centered
					title={titleModalPreview || t('productForSell.item-image')}>
					{mediaType === 'video' ? (
						<video src={videoUrlForPreview} autoPlay controls />
					) : (
						<img className={'width100'} src={previewFile} alt='' />
					)}
				</Modal>
			</>
		)
	}
)

export default React.memo(UploadCommon)

