import { Avatar, Divider, Table, Tooltip } from 'antd'
import Paragraph from 'antd/es/typography/Paragraph'
import { t } from 'i18next'
import lodash from 'lodash'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import TruncateText from '../../../components/TruncateText'
import { formatDateTime } from '../../../core/helpers/date-time'
import { formatMoneyByUnit } from '../../../core/helpers/string'
import { ProviderInterface } from '../../../Interface/AccountBalanceInterface'
import { ConnectionInterface } from '../../../Interface/ConnectionInterface'
import ShipmentsFilterInterface from '../../../Interface/ShipmentFilterInterface'
import ShipmentInterface from '../../../Interface/ShipmentInterface'
import ShipmentStatusInterface from '../../../Interface/ShipmentStatusInterface'
import ShipmentImageDefault from '../../../resources/images/shipment-ubox-default.png'
import RowExtended from '../../Orders/List/RowExtended'
import HeaderList from './HeaderList'

interface DataTableProps {
  isSkeleton: boolean;
  loading: boolean;
  total: number;
  filter: ShipmentsFilterInterface | {};
  accounts: ConnectionInterface[];
  statuses: ShipmentStatusInterface[];
  providers: ProviderInterface[];
  data: ShipmentInterface[];
  typeDataTable: string;
  handleTypeTableData: (val: string) => void;
}
const DataTable: React.FC<DataTableProps> = (props) => {
  const {
    handleTypeTableData,
    total,
    data,
    accounts,
    statuses,
    providers,
    typeDataTable,
  } = props;
  const [expandedRows] = useState<Array<any>>([]);

  const columns = [
    {
      title: (
        <span className="robotoregular fsz-14 line-h-22 txt-color-gray4">
          {t("shipment.codeTitle")}
        </span>
      ),
      width: "20%",
      render: (record: any) => {
        const account = accounts.find(
          (acc: ConnectionInterface) =>
            acc.provider.code === record.provider &&
            acc.name === record.providerUsername
        );
        return (
          <div className="flex mg-r-25 flex-nowrap width100pc">
            <Avatar
              src={record.image ? record.image : ShipmentImageDefault}
              shape="square"
              size={80}
              className="bd-none-impt rad-6 mg-r-12"
            />
            <div className="flex flex-col">
              <div className="flex mg-bt-6">
                <Link to={`/shipments/${record.code}`} className="mg-r-16">
                  <Paragraph
                    className="robotomedium mg-0 txt-color-blue6 fsz-14 line-h-22 order-heading whitespace"
                    copyable={{
                      text: record.code,
                      icon: [
                        <Tooltip
                          title={
                            <span className="robotoregular fsz-12 txt-color-black line-h-20">
                              Copy
                            </span>
                          }
                          color="#FBB739"
                        >
                          <i className="far fa-copy txt-color-black line-h-14 w-12"></i>
                        </Tooltip>,
                        <Tooltip
                          title={
                            <span className="robotoregular fsz-12 txt-color-black line-h-20">
                              Copied
                            </span>
                          }
                          color="#FBB739"
                        >
                          <i className="far fa-check fsz-12 line-h-14 w-12"></i>
                        </Tooltip>,
                      ],
                      tooltips: [false, false],
                    }}
                  >
                    {`#${record.code}`}
                  </Paragraph>
                </Link>
                <div className="flex">
                  <Tooltip
                    color="#FBB739"
                    title={
                      <span className="robotoregular fsz-12 txt-color-black line-h-20">
                        {t("shipments.quantifyTooltip")}
                      </span>
                    }
                  >
                    <span className="mg-r-3">{`${
                      record.orderedQuantity ? record.orderedQuantity : "---"
                    } / ${
                      record.receivedQuantity ? record.receivedQuantity : "---"
                    }`}</span>
                  </Tooltip>
                </div>
              </div>
              <div className="flex align-items-center mg-bt-6">
                <div className="flex align-items-center mg-r-24">
                  <i className="fa-light fa-store mg-r-4" />
                  <span className="txt-color-black fsz-12 line-h-20 w-70">
                    {record.merchantName ? record.merchantName : "---"}
                  </span>
                </div>
                <div className={"dpl-flex align-items-center fsz-14 line-h-22"}>
                  <Avatar
                    size={20}
                    src={account?.provider?.logo}
                    shape={"circle"}
                    className="bd-none-impt mg-r-4"
                  />
                  <span className="txt-color-black fsz-14 line-h-22 ">
                    {account?.provider.code}
                  </span>
                  <Divider
                    type={"vertical"}
                    className={"bd-color-black h-13 mx-4 "}
                    style={{ top: 0 }}
                  />
                  <span className={"robotoregular fsz-14 line-h-22 "}>
                    {account?.name}
                  </span>
                </div>
              </div>
              <div className="flex">
                <span className="txt-color-gray4 fsz-14 line-h-22 mg-r-3 whitespace">
                  {t("shipments.refCustomerCodeLabel")}:
                </span>
                <TruncateText
                  className="fsz-14 line-h-22"
                  defaultValue={
                    record.refShipmentCode ? record.refShipmentCode : "---"
                  }
                />
              </div>
              {/* {record.services.length > 0 && (
								<div className='flex'>
									<span className='txt-color-gray fsz-14 line-h-22 mg-r-2 whitespace'>{`${t(
										'shipments.services'
									)}:`}</span>
									<Services
										data={record.services}
										className='fsz-14 line-h-22'
										dividerClass='mx-1 service-divider'
										// wrapperClass='flex-nowrap'
									/>
								</div>
							)} */}
            </div>
          </div>
        );
      },
    },
    {
      title: (
        <span className="robotoregular fsz-14 line-h-22 txt-color-gray4">
          {t("shipments.createdAt")}
        </span>
      ),
      align: "right" as "right",
      width: 120,
      render: (record: any) => {
        return (
          <span className="fsz-14 line-h-22 txt-color-gray4 whitespace">
            {record.timestamp ? formatDateTime(record.timestamp) : "---"}{" "}
          </span>
        );
      },
    },
    {
      title: (
        <span className="robotoregular fsz-14 line-h-22 txt-color-gray4">
          {t("shipments.financeTotalFees")}
        </span>
      ),
      align: "right" as "right",
      width: 180,
      render: (record: any) => {
        const provider = providers.find(
          (prod: ProviderInterface) => prod.code === record.provider
        );
        let currency;
        const currencyJsonStr = provider?.currency;
        if (currencyJsonStr) {
          currency = JSON.parse(currencyJsonStr).code;
        }
        return (
          <div className="flex justify-content-end">
            <span className="fsz-14 line-h-22 txt-color-black whitespace">{`${
              record.totalFee
                ? formatMoneyByUnit(record.totalFee, currency)
                : "---"
            }`}</span>
          </div>
        );
      },
    },
    {
      title: (
        <span className="robotoregular fsz-14 line-h-22 txt-color-gray4">
          {t("shipments.provisionalAmountTooltip")}
        </span>
      ),
      width: 180,
      align: "right" as "right",
      render: (record: any) => {
        return (
          <div className="flex justify-content-end">
            <span className="fsz-14 line-h-22 txt-color-black whitespace">{`${
              record.totalValue
                ? formatMoneyByUnit(record.totalValue, record.currency)
                : "---"
            }`}</span>
          </div>
        );
      },
    },
    {
      title: (
        <span className="robotoregular fsz-14 line-h-22 txt-color-gray4">
          {t("orderDetail.needPay")}
        </span>
      ),
      align: "right" as "right",
      width: 180,
      render: (record: any) => {
        return (
          <span className="txt-color-red2 fsz-14 line-h-22">{`${
            record.totalUnpaid
              ? formatMoneyByUnit(record.totalUnpaid)
              : formatMoneyByUnit(0)
          }`}</span>
        );
      },
    },
    {
      title: (
        <span className="robotoregular fsz-14 line-h-22 txt-color-gray4">
          {t("shipments.statusOfShipment")}
        </span>
      ),
      align: "right" as "right",
      width: 180,
      render: (record: any) => {
        const status = statuses.find(
          (stt: ShipmentStatusInterface) => stt.code === record.status
        );
        return (
          <span
            className="line-h-22 rad-25 txt-color-white px-16 py-6 txt-capitalize fsz-14   robotomedium whitespace"
            style={{
              backgroundColor: `${status?.color ? status.color : "#0082E6"}`,
            }}
          >
            {status?.name}
          </span>
        );
      },
    },
  ];
  const components = { body: { row: RowExtended } };

  return (
    <Table
      rowKey={(record: any) => record.id}
      columns={columns}
      dataSource={data}
      pagination={false}
      className="table-improved"
      components={components}
      onRow={(record, index) => {
        const rowClassesByIdx = index! % 2 === 0 ? "row-dark" : "row-light";
        return { ...record, index, rowClassesByIdx };
      }}
      title={() => (
        <HeaderList
          total={total}
          typeDataTable={typeDataTable}
          setTypeDataTable={(val: string) => handleTypeTableData(val)}
        />
      )}
      scroll={{ x: 526 }}
      rowClassName={(record, idx) => {
        const rowClassesActiveByExpanded = lodash.includes(
          expandedRows,
          record.id
        )
          ? "active-row cursor-pointer"
          : "data-row cursor-pointer";
        const rowClassesByIdx = idx % 2 === 0 ? "row-dark" : "row-light";
        return `${rowClassesActiveByExpanded} ${rowClassesByIdx}`;
      }}
    />
  );
};

export default DataTable;