import { Tooltip } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { DATA_TABLE_LAYOUT, DATA_TABLE_LIST } from '../../../core/config'
interface HeadingListProps {
    pagination: any
    typeShowData: string
    setTypeShowData: (val: string) => void
}
const HeadingList:React.FC<HeadingListProps> = (props) => {
    const {typeShowData, pagination, setTypeShowData} = props
    const {t} = useTranslation()
	return (
		<>
			<div className='dpl-flex align-items-center'>
				<h3 className='robotomedium mg-0 fsz-16 line-h-24 txt-color-black mg-r-4'>{t('breadcrumbs.orders')}</h3>
				<span
					className={`${
						pagination?.total > 0 ? 'bg-color-primary txt-color-black' : 'bg-color-gray-badge txt-color-gray4'
					} border-radius20  fsz-12 line-h-20 px-8 mg-r-20`}>
					{pagination?.total}
				</span>
				<Tooltip
					title={<span className='robotoregular fsz-12 txt-color-white line-h-20'>{t('shipments.listLayout')}</span>}
					color='#FBB739'>
					<span
						className={`${
							typeShowData === DATA_TABLE_LIST ? 'rad-4 bg-color-pink2' : ''
						} cursor-pointer mg-r-10 line-h-16  flex justify-content-center align-items-center w-26 h-26`}>
						<i
							className={`${
								typeShowData === DATA_TABLE_LIST ? 'txt-color-orange3' : ''
							} fsz-16 line-h-16 fa-light  fa-table-cells cursor-pointer txt-color-gray2`}
							onClick={() => setTypeShowData(DATA_TABLE_LIST)}></i>
					</span>
				</Tooltip>
				<Tooltip
					title={<span className='robotoregular fsz-12 txt-color-white line-h-20'>{t('shipments.tableLayout')}</span>}
					color='#FBB739'>
					<span
						className={`${
							typeShowData === DATA_TABLE_LAYOUT ? 'rad-4 bg-color-pink2' : ''
						} w-26 h-26 cursor-pointer line-h-16  justify-content-center align-items-center flex justify-content-center align-items-center`}>
						<i
							className={`fsz-16 fa-light fa-table-list  cursor-pointer txt-color-gray2 ${
								typeShowData === DATA_TABLE_LAYOUT ? 'txt-color-orange3' : ''
							}`}
							onClick={() => setTypeShowData(DATA_TABLE_LAYOUT)}></i>
					</span>
				</Tooltip>
			</div>
		</>
	)
}

export default HeadingList
