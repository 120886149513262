import { Avatar, Button, Divider, List, Tooltip } from 'antd'
import Paragraph from 'antd/lib/typography/Paragraph'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useSearchParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import M24Pagination from '../../components/Pagination/M24Pagination'
import Services from '../../components/Services'
import { formatDateTime } from '../../core/helpers/date-time'
import { formatMoneyByUnit } from '../../core/helpers/string'
import { ProviderInterface } from '../../Interface/AccountBalanceInterface'
import { ConnectionInterface } from '../../Interface/ConnectionInterface'
import MarketPalaceInterface from '../../Interface/MarketPalaceInterface'
import PeerpaymentInterface from '../../Interface/PeerpaymentInterface'
import ShipmentImageDefault from '../../resources/images/shipment-ubox-default.png'
import { getCleanFormFilter } from '../../utils/StringUtils'
import SkeletonOrders from '../Orders/SkeletonOrders'
import PeerpaymentStatusInterface from '../../Interface/PeerpaymentStatusInterface'
import PeerpaymentFilterInterface from '../../Interface/PeerpaymentFilterInterface'
import lodash from 'lodash'
import EmptyDataFilter from '../../components/Empty/EmptyDataFilter'

interface PeerpaymentmentItemsProps {
	data: PeerpaymentInterface[]
	accounts: ConnectionInterface[]
	statuses: PeerpaymentStatusInterface[]
	loading: boolean
	page: number
	size: number
	total: number
	filter: PeerpaymentFilterInterface | {}
	marketPalaces: MarketPalaceInterface[]
	providers: ProviderInterface[]
	getData: () => void
	handleFilter: (params: PeerpaymentFilterInterface | {}) => void
	peerPaymentAndShipmentStatuses: any
	partnerOfShipments: any
}

const PeerPaymentItems: React.FC<PeerpaymentmentItemsProps> = (props) => {
	const [searchParams] = useSearchParams()
	const {
		data,
		accounts,
		statuses,
		loading,
		filter,
		page,
		size,
		total,
		handleFilter,
		peerPaymentAndShipmentStatuses,
		partnerOfShipments,
	} = props
	const { t } = useTranslation()

	const [loadingState, setLoadingState] = useState(true)
	useEffect(() => {
		setTimeout(() => setLoadingState(false), 1000)
	})

	const pageChangeHandler = (filter: any) => {
		const { page, size } = filter
		handleFilter(
			getCleanFormFilter(
				{
					...filter,
					page: (page - 1).toString(),
					size: size.toString(),
				},
				[],
				['pageSize']
			)
		)
	}

	return (
		<>
			{!loading && !loadingState && (
				<List
					className='shipments-items border-radius6 '
					pagination={false}
					header={
						<div className='flex align-items-center justify-content-between'>
							<div className='dpl-flex align-items-center'>
								<h3 className='robotomedium mg-0 fsz-16 line-h-24 txt-color-black mg-r-4'>
									{t('peerpayments.list')}
								</h3>
								<span
									className={`${
										total > 0
											? 'bg-color-primary txt-color-black'
											: 'bg-color-gray-badge txt-color-gray4'
									} border-radius20  fsz-14 line-h-20 px-8`}
								>
									{total}
								</span>
							</div>
						</div>
					}
					locale={{
						emptyText:
							!searchParams.get('code') &&
							!searchParams.get('statuses') &&
							!searchParams.get('providerUsername') &&
							!searchParams.get('provider') &&
							!searchParams.get('peerPaymentUsername') &&
							!searchParams.get('peerPaymentProvider') &&
							!searchParams.get('peerPaymentCode') &&
							!searchParams.get('originalReceipt') ? (
								<EmptyDataFilter description={t('peerpayments.empty')} />
							) : (
								<EmptyDataFilter description={t('peerpayments.filterEmpty')} />
							),
					}}
					dataSource={data}
					renderItem={(item: PeerpaymentInterface, index: number) => {
						const status = statuses.find((stt: PeerpaymentStatusInterface) => stt.code === item.status)

						const peerPaymentAndShipmentStt = peerPaymentAndShipmentStatuses.find(
							(peerPaymentAndShipmentStt: any) =>
								peerPaymentAndShipmentStt.code === item.peerPaymentStatus
						)

						const accs = lodash.map(accounts, 'connection')
						const account = accs?.find(
							(acc: ConnectionInterface) =>
								acc.provider.code === item.provider && acc.name === item.providerUsername
						)

						const partnerShipments = lodash.map(partnerOfShipments, 'connection')
						const partnerShipment = partnerShipments?.find(
							(acc: ConnectionInterface) =>
								acc.provider.code === item.peerPaymentProvider && acc.name === item.peerPaymentUsername
						)

						return (
							<List.Item
								key={item.id}
								className={`cursor-pointer ${index % 2 === 0 ? ' ' : 'row-light-peerPayment'}`}
							>
								<List.Item.Meta
									avatar={
										<Avatar
											src={item.image ? item.image : ShipmentImageDefault}
											shape='square'
											size={80}
											className='bd-none-impt avatar-square'
										/>
									}
									title={
										<>
											<div className='dpl-flex'>
												<div className='dpl-grid mg-r-20 width15'>
													<Paragraph
														className='robotomedium mg-0 txt-color-blue6 fsz-14 order-heading whitespace'
														copyable={{
															text: item?.code || '',
															icon: [
																<Tooltip
																	title={
																		<span className='robotoregular fsz-14 txt-color-black line-h-20'>
																			Copy
																		</span>
																	}
																	color='#FBB739'
																>
																	<i className='far fa-copy txt-color-black line-h-14 w-12'></i>
																</Tooltip>,
																<Tooltip
																	title={
																		<span className='robotoregular fsz-14 txt-color-black line-h-20'>
																			Copied
																		</span>
																	}
																	color='#FBB739'
																>
																	<i className='far fa-check fsz-14 line-h-14 w-12'></i>
																</Tooltip>,
															],
															tooltips: [false, false],
														}}
													>
														<span className='robotomedium'>{item.code}</span>
													</Paragraph>

													<div className='mg-t-8'>
														<Tooltip
															color='#FBB739'
															title={
																<span className='robotoregular fsz-14 txt-color-black line-h-20'>
																	{t('peerpayments.createAtTooltip')}
																</span>
															}
														>
															<span className=' txt-color-black fsz-14 cursor-pointer line-h-20'>
																{item.timestamp
																	? formatDateTime(item.timestamp)
																	: '---'}{' '}
															</span>
														</Tooltip>
													</div>
												</div>
												<div className='dpl-grid mg-r-20 width20'>
													<div>
														<Tooltip
															color='#FBB739'
															title={
																<span className='robotoregular fsz-14 txt-color-black line-h-20'>
																	{/* {t('peerpayments.createAtTooltip')} */}Người bán
																</span>
															}
														>
															<span className=' txt-color-black fsz-14 cursor-pointer line-h-20'>
																<i className='fa-light fa-store mg-r-4' />
																{item?.merchantName || '---'}
															</span>
														</Tooltip>
													</div>

													<div className='mg-t-8'>
														<Tooltip
															color='#FBB739'
															title={
																<span className='robotoregular fsz-14 txt-color-black '>
																	Số lượng gửi/nhận
																</span>
															}
														>
															<span className='fsz-14 '>
																{`${
																	item.orderedQuantity ? item.orderedQuantity : '---'
																} / ${
																	item.receivedQuantity
																		? item.receivedQuantity
																		: '---'
																}`}
															</span>
														</Tooltip>
													</div>
												</div>
												<div className='dpl-grid mg-r-20 width20'>
													<div>
														<Tooltip
															color='#FBB739'
															title={
																<span className='robotoregular fsz-14 txt-color-black '>
																	Đối tác ký gửi
																</span>
															}
														>
															<span>
																{lodash.get(account, 'provider.logo', '') ? (
																	<Avatar
																		size={20}
																		src={lodash.get(account, 'provider.logo', '')}
																		shape={'circle'}
																		className='bd-none-impt mg-r-4 mg-l-4'
																	/>
																) : (
																	''
																)}

																<span
																	className={`txt-color-black fsz-14 ${
																		lodash.get(account, 'provider.code', '')
																			? ''
																			: 'mg-l-8'
																	}`}
																>
																	{lodash.get(account, 'provider.code', '')
																		? lodash.get(account, 'provider.code', '')
																		: '---'}
																</span>
																<Divider
																	type={'vertical'}
																	className={'bd-color-black'}
																/>
																<span className={'robotoregular txt-size-h8 fsz-14'}>
																	{lodash.get(account, 'name', '')
																		? lodash.get(account, 'name', '')
																		: '---'}
																</span>
															</span>
														</Tooltip>
													</div>

													<div className='mg-t-8'>
														<Tooltip
															color='#FBB739'
															title={
																<span className='robotoregular fsz-14 txt-color-black '>
																	Đối tác thanh toán
																</span>
															}
														>
															<span>
																{lodash.get(partnerShipment, 'provider.logo', '') ? (
																	<Avatar
																		size={20}
																		src={lodash.get(
																			partnerShipment,
																			'provider.logo',
																			''
																		)}
																		shape={'circle'}
																		className='bd-none-impt mg-r-4 mg-l-4'
																	/>
																) : (
																	''
																)}
																<span
																	className={`txt-color-black fsz-14 ${
																		lodash.get(partnerShipment, 'provider.code', '')
																			? ''
																			: 'mg-l-8'
																	}`}
																>
																	{lodash.get(partnerShipment, 'provider.code', '')
																		? lodash.get(
																				partnerShipment,
																				'provider.code',
																				''
																		  )
																		: '---'}
																</span>
																<Divider
																	type={'vertical'}
																	className={'bd-color-black'}
																/>
																<span className={'robotoregular txt-size-h8 fsz-14'}>
																	{lodash.get(partnerShipment, 'name', '')
																		? lodash.get(partnerShipment, 'name', '')
																		: '---'}
																</span>
															</span>
														</Tooltip>
													</div>
												</div>
												<div className='dpl-grid mg-r-20 width20'>
													<div>
														<Tooltip
															color='#FBB739'
															title={
																<span className='robotoregular fsz-14 txt-color-black '>
																	Trạng thái yêu cầu thanh toán
																</span>
															}
														>
															<span
																className='fsz-14'
																style={{
																	color: `${
																		peerPaymentAndShipmentStt?.color
																			? peerPaymentAndShipmentStt?.color
																			: '#817873'
																	}`,
																}}
															>
																{peerPaymentAndShipmentStt?.name || '---'}
															</span>
														</Tooltip>
													</div>

													<div className='mg-t-8'>
														<Tooltip
															color='#FBB739'
															title={
																<span className='robotoregular fsz-14 txt-color-black '>
																	Mã yêu cầu thanh toán
																</span>
															}
														>
															<span
																className={` ${
																	item?.peerPaymentCode
																		? ' txt-color-blue6 fsz-14 robotomedium '
																		: ''
																} `}
															>
																{item?.peerPaymentCode || '---'}
															</span>
														</Tooltip>
														{item?.peerPaymentCode && (
															<Paragraph
																className='robotomedium mg-0  mg-t-5 order-heading dpl-il'
																copyable={{
																	text: item?.peerPaymentCode || '---',
																	icon: [
																		<Tooltip
																			title={
																				<span className='robotoregular fsz-14 txt-color-black line-h-20'>
																					Copy
																				</span>
																			}
																			color='#FBB739'
																		>
																			<i className='far fa-copy txt-color-black line-h-14 w-12'></i>
																		</Tooltip>,
																		<Tooltip
																			placement='rightTop'
																			title={
																				<span className='robotoregular fsz-14 txt-color-black line-h-20'>
																					Copied
																				</span>
																			}
																			color='#FBB739'
																		>
																			<i className='far fa-check fsz-14 line-h-14 w-12'></i>
																		</Tooltip>,
																	],
																	tooltips: [false, false],
																}}
															></Paragraph>
														)}
													</div>
												</div>
												<div className='dpl-grid mg-r-20 width20'>
													<div>
														<Tooltip
															color='#FBB739'
															title={
																<span className='robotoregular fsz-14 txt-color-black '>
																	Tổng chi phí/Tiền hàng
																</span>
															}
														>
															<span className='line-h-20 txt-color-black robotomedium whitespace fsz-14'>
																{`${
																	item.totalFee
																		? formatMoneyByUnit(item.totalFee)
																		: '---'
																} / ${
																	item.totalValue
																		? formatMoneyByUnit(
																				item.totalValue,
																				item.currency
																		  )
																		: '---'
																}`}
															</span>
														</Tooltip>
													</div>
													<div className='mg-t-8'>
														<Tooltip
															color='#FBB739'
															title={
																<span className='robotoregular fsz-14 txt-color-black '>
																	Cần thanh toán
																</span>
															}
														>
															<span className='txt-color-red2 mg-t-5 mg-r-18 robotomedium fsz-14'>
																{`${
																	item.totalUnpaid
																		? formatMoneyByUnit(item.totalUnpaid)
																		: formatMoneyByUnit(0)
																}`}
															</span>
														</Tooltip>
													</div>
												</div>
											</div>

											<div className='mg-t-5'>
												{item.services.length > 0 && (
													<div className='mg-r-18 flex'>
														<span className='txt-color-gray fsz-14 line-h-20 mg-r-2 whitespace'>{`${t(
															'peerpayments.services'
														)}:`}</span>
														<Services className='fsz-14' data={item.services} />
													</div>
												)}
											</div>
										</>
									}
								/>
								<div className='dpl-grid justify-items-end'>
									<div className='dpl-flex'>
										<span
											className='line-h-20 rad-25 txt-color-white px-12 py-2 txt-capitalize'
											style={{
												backgroundColor: `${status?.color ? status.color : '#0082E6'}`,
											}}
										>
											{status?.name}
										</span>
									</div>

									{item?.peerPaymentStatus === 'REQUEST_FOR_PAY' && (
										<button
											// icon={<i className='fa-regular fa-check mg-r-5'></i>}
											className='btn mg-t-10 pd5 border-radius4 fsz-14 w-130 cursor-pointer'
											style={{ backgroundColor: '#FCD535', border: 'none' }}
										>
											<span>Thanh Toán</span>
										</button>
									)}
								</div>
							</List.Item>
						)
					}}
				></List>
			)}
			{(loading || loadingState) && <SkeletonOrders data={data} loading={loadingState || loading} />}
			<div className='flex justify-content-end'>
				{data && data.length > 0 && (
					<M24Pagination
						onPageChange={pageChangeHandler}
						filter={{ ...filter, page, size }}
						pagination={{
							current: page,
							pageSize: size,
							hideOnSinglePage: true,
							total,
						}}
						className='mg-t-16'
					/>
				)}
			</div>
		</>
	)
}

export default PeerPaymentItems