import { Avatar, Image, Drawer, Layout, Menu, MenuProps } from 'antd'
import lodash from 'lodash'
import { features } from 'process'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import { TENANT_KEY } from '../../core/config'
import Logo from '../../resources/images/dau-hop.png'
import { localStorageRead } from '../../utils/LocalStorageUtils'
import ShopeeImg from '../../resources/images/shopee-menu-icon.png'
import LazadaImg from '../../resources/images/lazada-menu-icon.png'
const { Sider } = Layout
interface SidebarProps {
	activeKey: string
	currentConfigServices: any
	handleShowSubMenu: (val: boolean) => void
	openSubmenu: boolean
	handleOpenNestedDrawer: (val: boolean) => void
	openNestedDrawer: boolean
}

const Sidebar: React.FC<SidebarProps> = (props) => {
	const {
		// collapsed,
		// toggleHandler,
		activeKey,
		openSubmenu,
		currentConfigServices,
		// specificToggle,
		handleShowSubMenu,
		openNestedDrawer,
		handleOpenNestedDrawer,
	} = props
	const [, setCurrentConfigShowBalance] = useState()

	const navigate = useNavigate()
	const { t } = useTranslation()
	const [menu, setMenu] = useState<any>()
	const [currentMenuItems, setCurrentMenuItems] = useState<any>([])
	type MenuItem = Required<MenuProps>['items'][number]
	const [currentChildrenDrawer, setCurrentChildrenDrawer] = useState<any>()

	const handleChangeDrawer = (items: any) => {
		if (!openSubmenu) {
			handleShowSubMenu(true)
		}
		setCurrentMenuItems(items)
		handleOpenNestedDrawer(false)
	}

	useEffect(() => {
		let connectItems = {
			title: t('menu.connect'),
			items: [
				{
					key: 'connect-supplier',
					icon: (
						<i
							className={`fa-${activeKey === 'connect-supplier' ? 'regular' : 'light'} fa-user fsz-16 ${
								activeKey === 'connect-supplier' ? 'txt-color-black' : 'txt-color-gray8 icon-hover'
							}`}
						/>
					),
					label: t('menu.connectSupplier'),
				},
			],
		}

		let productItems = {
			title: t('menu.products'),
			items: [
				{
					key: 'warehouse/stored-products',
					icon: (
						<i
							className={`fa-solid fa-shop fsz-16 ${
								activeKey === 'stored-products' ? 'txt-color-black' : 'txt-color-gray8 icon-hover'
							}`}
						></i>
					),
					label: t('menu.storedProductList'),
				},
			],
		}

		let financeItems = {
			title: t('menu.finance'),
			items: [
				{
					key: 'transactions',
					icon: (
						<i
							className={`fa-${
								activeKey === 'transactions' ? 'regular' : 'light'
							} fa-arrow-trend-up fsz-16 ${
								activeKey === 'transactions' ? 'txt-color-black' : 'txt-color-gray8 icon-hover'
							}`}
						/>
					),
					label: t('menu.transactions'),
				},
			],
		}

		let deliveryItems = {
			title: t('menu.packagesGroup'),
			items: [
				{
					key: 'packages',
					icon: (
						<i
							className={`fa-${
								activeKey === 'packages' ? 'regular' : 'light'
							} fa-cart-flatbed-boxes fsz-16 ${
								activeKey === 'packages' ? 'txt-color-black' : 'txt-color-gray8 icon-hover'
							}`}
						/>
					),
					label: t('menu.packages'),
				},
				{
					key: 'delivery',
					icon: (
						<i
							className={`fa-${activeKey === 'delivery' ? 'regular' : 'light'} fa-box-check fsz-16 ${
								activeKey === 'delivery' ? 'txt-color-black' : 'txt-color-gray8 icon-hover'
							}`}
						/>
					),
					label: t('menu.requestDelivery'),
				},
				{
					key: 'delivery-notes',
					icon: (
						<i
							className={`fa-${
								activeKey === 'delivery-notes' ? 'regular' : 'light'
							} fa-clipboard-list fsz-16 ${
								activeKey === 'delivery-notes' ? 'txt-color-black' : 'txt-color-gray8 icon-hover'
							}`}
						/>
					),
					label: t('menu.delivery-notes'),
				},
			],
		}

		let ordersItems = {
			title: t('menu.ordersGroup'),
			items: [
				{
					key: 'orders',
					icon: (
						<i
							className={`fa-${activeKey === 'orders' ? 'regular' : 'light'} fa-bags-shopping fsz-16 ${
								activeKey === 'orders' ? 'txt-color-black' : 'txt-color-gray8 icon-hover'
							}`}
						/>
					),
					// label: t('menu.orders'),
					label: 'Đơn Mua Hộ',
				},
				{
					key: 'shipments',
					icon: (
						<i
							className={`fa-${activeKey === 'shipments' ? 'regular' : 'light'} fa-paste fsz-16 ${
								activeKey === 'shipments' ? 'txt-color-black' : 'txt-color-gray8 icon-hover'
							}`}
						></i>
					),
					label: t('menu.shipments'),
				},
				{
					key: 'peerpayments',
					icon: (
						<i
							className={`fa-${activeKey === 'peerpayments' ? 'regular' : 'light'} fa-box-dollar fsz-16 ${
								activeKey === 'peerpayments' ? 'txt-color-black' : 'txt-color-gray8 icon-hover'
							}`}
						/>
					),
					label: t('menu.peermentsTitle'),
				},

				{
					key: 'claims',
					icon: (
						<i
							className={`fa-${activeKey === 'claims' ? 'regular' : 'light'} fa-memo-circle-info fsz-16 ${
								activeKey === 'claims' ? 'txt-color-black' : 'txt-color-gray8 icon-hover'
							}`}
						/>
					),
					label: t('menu.tickets'),
				},
			],
		}

		let gsaItems = {
			title: t('menu.linked-channels'),
			items: [
				{
					key: 'warehouse/stored-products',
					icon: (
						<i
							className={`fa-${
								activeKey === 'stored-products' ? 'regular' : 'light'
							} fa-bookmark fsz-16 ${
								activeKey === 'stored-products' ? 'txt-color-black' : 'txt-color-gray8 icon-hover'
							}`}
						></i>
					),
					label: t('menu.storedProductList'),
				},
				{
					key: 'price-config',
					icon: (
						<i
							className={`fa-${
								activeKey === 'price-config' ? 'regular' : 'light'
							} fa-money-check-dollar-pen fa-gear fsz-16 ${
								activeKey === 'price-config' ? 'txt-color-black' : 'txt-color-gray8 icon-hover'
							}`}
						></i>
					),
					label: t('menu.price-config'),
				},
				{
					title: '',
					label: 'Sàn thương mại liên kết',
					icon: <i className='fa-light fa-bag-shopping fsz-16'></i>,
					dropdownItems: [
						{
							icon: <Image src={ShopeeImg} preview={false} />,
							label: 'Shopee',
							key: 'shopee',
							items: [
								{
									icon: (
										<i
											className={`fa-${
												activeKey === 'pending-products/shopee' ? 'regular' : 'light'
											} fa-hand-holding-box fsz-16`}
										/>
									),
									label: 'Chờ đăng bán',
									key: 'pending-products/shopee',
								},
								{
									icon: (
										<i
											className={`fa-${
												activeKey === 'selling-products/shopee' ? 'regular' : 'light'
											} fa-tag fsz-16`}
										></i>
									),
									label: 'Đang bán',
									key: 'selling-products/shopee',
								},
							],
						},
						{
							icon: <Image src={LazadaImg} preview={false} />,
							label: 'Lazada',
							key: 'lazada',
						},
					],
				},
				{
					key: 'market-connect',
					icon: (
						<i
							className={`fa-${
								activeKey === 'market-connect' ? 'regular' : 'light'
							} fa-house-signal fsz-16 ${
								activeKey === 'market-connect' ? 'txt-color-black' : 'txt-color-gray8 icon-hover'
							}`}
						/>
					),
					label: t('menu.connect-market'),
				},
			],
		}

		if (!currentConfigServices?.enableGsa) {
			connectItems?.items.filter((item) => item.key !== 'market-conne15px !importantct')
			productItems?.items.filter((item) => item.key !== 'warehouse/stored-products')
			const gsaItem: any = gsaItems?.items[2]?.dropdownItems
			gsaItem.filter(
				(item: any) =>
					item.key !== 'selling-products/shopee' &&
					item.key !== 'pending-products/shopee'
			)
		}
		if (!currentConfigServices?.peerPaymentConfig || !currentConfigServices?.shipmentConfig) {
			ordersItems?.items.filter((item) => item.key !== 'peerpayments')
		}
		if (!currentConfigServices?.enableOrder) {
			ordersItems.items.filter((item) => item.key !== 'orders')
		}
		if (!currentConfigServices?.enableShipment) {
			ordersItems.items.filter((item) => item.key !== 'shipments')
		}
		if (!currentConfigServices?.enableShipment && !currentConfigServices?.enableOrder) {
			connectItems.items.filter((item) => item.key !== 'connect-supplier')
			productItems.items.filter((item) => item.key !== 'shopping-cart')
			financeItems?.items.filter((item) => item.key !== 'transactions')
			deliveryItems.items.filter(
				(item) => item.key !== 'packages' && item.key !== 'delivery-notes' && item.key !== 'delivery'
			)
			ordersItems.items.filter((item) => item.key !== 'claims')
		}
		let items: any[] = [
			// ************************ ORDERS ************************
			{
				title: t('menu.home'),
				key: 'home',
				children: [],
				icon: (
					<Link to={'/'} className={`  ${activeKey === '' ? 'menu-active ' : ''}`}>
						<i
							className={`fa-${activeKey === '' ? 'regular' : 'light'} fa-house ${
								activeKey === '' ? 'txt-color-black' : 'home'
							} `}
						></i>
					</Link>
				),
				label: (
					<Link className={`${activeKey === '' ? 'txt-color-black' : 'home'} fsz-12`} to={'/'}>
						Trang chủ
					</Link>
				),
			},
			{
				title: t('menu.ordersGroup'),
				key: 'orders-group',
				children: ordersItems?.items,
				icon: (
					<i
						className={`fa-${
							activeKey === 'orders' ||
							activeKey === 'shipments' ||
							activeKey === 'peerpayments' ||
							activeKey === 'claims'
								? 'regular'
								: 'light'
						} fa-ballot-check ${
							activeKey === 'orders' ||
							activeKey === 'shipments' ||
							activeKey === 'peerpayments' ||
							activeKey === 'claims'
								? 'menu-active '
								: ''
						} `}
						onMouseEnter={() => {
							handleChangeDrawer(ordersItems)
						}}
					></i>
				),
				label: (
					<span
						className={`${
							activeKey === 'orders' ||
							activeKey === 'shipments' ||
							activeKey === 'peerpayments' ||
							activeKey === 'claims'
								? 'menu-text-active '
								: ''
						}`}
						// onMouseEnter={() => {
						// 	handleChangeDrawer(ordersItems)
						// }}
					>
						{t('menu.ordersGroup')}
					</span>
				),
			},
			// ************************ FINANCE ************************

			{
				title: t('menu.finance'),
				key: 'finance',
				children: financeItems?.items,
				icon: (
					<i
						className={`fa-${activeKey === 'transactions' ? 'regular' : 'light'} fa-list-dropdown ${
							activeKey === 'transactions' ? 'menu-active' : ''
						}`}
						onMouseEnter={() => {
							handleChangeDrawer(financeItems)
						}}
					></i>
				),
				label: (
					<span
						className={`${activeKey === 'transactions' ? 'menu-text-active ' : ''}`}
						// onMouseEnter={() => {
						// 	handleChangeDrawer(financeItems)
						// }}
					>
						{t('menu.finance')}
					</span>
				),
			},

			// ************************ PACKAGES ************************

			{
				title: t('menu.packagesGroup'),
				key: 'delivery-group',
				children: deliveryItems?.items,
				icon: (
					<i
						className={`fa-${
							activeKey === 'packages' || activeKey == 'delivery' || activeKey === 'delivery-notes'
								? 'regular'
								: 'light'
						} fa-truck ${
							activeKey === 'packages' || activeKey == 'delivery' || activeKey === 'delivery-notes'
								? 'menu-active'
								: ''
						}`}
						onMouseEnter={() => {
							handleChangeDrawer(deliveryItems)
						}}
					></i>
				),
				label: (
					<span
						className={`${
							activeKey === 'packages' || activeKey == 'delivery' || activeKey === 'delivery-notes'
								? 'menu-text-active '
								: ''
						}`}
						// onMouseEnter={() => {
						// 	handleChangeDrawer(deliveryItems)
						// }}
					>
						{t('menu.packagesGroup')}
					</span>
				),
			},

			// ************************ CONNECT ************************
			{
				title: t('menu.packagesGroup'),
				label: (
					<span
						className={`${activeKey === 'connect-supplier' ? 'menu-text-active ' : ''}`}
						// onMouseEnter={() => {
						// 	handleChangeDrawer(connectItems)
						// }}
					>
						{t('menu.connect')}
					</span>
				),
				key: 'connect',
				children: connectItems?.items,
				icon: (
					<i
						className={`fa-${activeKey === 'connect-supplier' ? 'regular' : 'light'}	
					 fa-link ${activeKey === 'connect-supplier' ? 'menu-active' : ''}`}
						onMouseEnter={() => {
							handleChangeDrawer(connectItems)
						}}
					></i>
				),
			},

			{
				title: t('menu.linked-channels'),
				key: 'linked-channels',
				children: [],
				icon: (
					<i
						className={`fa-${
							activeKey === 'market-connect' ||
							activeKey === 'price-config' ||
							activeKey === 'warehouse/stored-products' ||
							activeKey === 'selling-products/shopee' ||
							activeKey === 'pending-products/shopee'
								? 'regular'
								: 'light'
						} fa-store ${
							activeKey === 'market-connect' ||
							activeKey === 'price-config' ||
							activeKey === 'warehouse/stored-products' ||
							activeKey === 'selling-products/shopee' ||
							activeKey === 'pending-products/shopee'
								? 'menu-active'
								: ''
						}`}
						onMouseEnter={() => {
							handleChangeDrawer(gsaItems)
						}}
					></i>
				),
				label: (
					<span
						className={`${
							activeKey === 'market-connect' ||
							activeKey === 'price-config' ||
							activeKey === 'warehouse/stored-products' ||
							activeKey === 'selling-products/shopee' ||
							activeKey === 'pending-products/shopee'
								? 'menu-text-active'
								: ''
						}`}
						// onMouseEnter={() => {
						// 	handleChangeDrawer(gsaItems)
						// }}
					>
						{t('menu.linked-channels')}
					</span>
				),
			},
		]
		const showBalance = lodash.get(localStorageRead(TENANT_KEY), 'config.showBalance', '')
		if (
			!currentConfigServices?.enableShipment &&
			!currentConfigServices?.enableOrder &&
			!currentConfigServices?.enableGsa
		) {
			items = items.filter((item: any) => item?.key !== 'connect')
		}
		if (!currentConfigServices?.enableShipment && !currentConfigServices?.enableOrder) {
			items = items.filter(
				(item: any) =>
					item?.key !== 'products' &&
					item?.key !== 'transactions' &&
					item?.key !== 'orders' &&
					item?.key !== 'delivery'
			)
		}

		if (!currentConfigServices?.enableGsa) {
			items = items.filter((item: any) => item?.key !== 'warehouse')
		}
		if (!currentConfigServices?.enableGsa) {
			items = items.filter((item: any) => item?.key !== 'linked-channels')
		}
		// items = items.filter((item: any) => item?.children.length > 0)
		setCurrentConfigShowBalance(showBalance)
		const menu = !showBalance ? items.filter((item) => item?.key !== 'transactions') : items
		setMenu(menu)
	}, [
		activeKey,
		currentConfigServices?.enableGeneral,
		currentConfigServices?.enableGsa,
		currentConfigServices?.enableOrder,
		currentConfigServices?.enableShipment,
		t,
	])

	return (
		<Sider
			collapsible
			collapsed={true}
			className={`sidebar-left`}
			theme='light'
			trigger={
				<div className='luna-theme'>
					<i className='fa-solid fa-moon txt-color-gray4'></i>
				</div>
			}
			collapsedWidth={88}
			width={240}
		>
			<div className='flex flex-col gap-4 align-items-center py-20'>
				<Image
					width={34}
					height={32}
					preview={false}
					src={Logo}
					alt='ubox-logo'
					className='cursor-pointer'
					onClick={() => navigate('/')}
				/>
			</div>
			<Drawer
				title={currentChildrenDrawer?.label}
				placement={'left'}
				closable={false}
				open={openNestedDrawer}
				className='menu-drawer-child'
				width={308}
				mask={false}
				onClose={() => handleOpenNestedDrawer(false)}
			>
				<div className='flex flex-col'>
					{currentChildrenDrawer?.items?.map((item: any) => (
						<Link
							to={`/${item.key}`}
							key={item.key}
							className={`flex align-items-center px-15 py-10 rad-16 mg-bt-6 ${
								activeKey === item.key ? 'bg-color-orange2' : 'bg-color-white  menu-hovered'
							} icon`}
						>
							<span className='txt-color-black mg-r-10 flex align-items-center'>{item.icon}</span>
							<span
								className={`txt-color-${
									activeKey === item.key ? 'black' : 'gray8'
								} fsz-14 line-h-22 robotolight`}
							>
								{item.label}
							</span>
						</Link>
					))}
				</div>
			</Drawer>
			<Drawer
				className='menu-drawer'
				open={openSubmenu}
				title={currentMenuItems.title}
				placement={'left'}
				width={308}
				mask={false}
				closable={false}
				onClose={() => handleShowSubMenu(false)}
				contentWrapperStyle={{ left: 88, zIndex: 998 }}
			>
				<div className='flex flex-col mg-t-15' style={{ marginLeft: '-4px' }}>
					{currentMenuItems?.items?.map((item: any, idx: number) => {
						return item.dropdownItems ? (
							<div key={idx}>
								<div
									// onMouseLeave={() => {
									// 	handleOpenNestedDrawer(false)
									// 	setCurrentChildrenDrawer(null)
									// }}
									className={`flex align-items-center px-20 py-10 rad-16 mg-bt-4 cursor-pointer menu-hovered icon px-20 ${
										idx === 0 ? 'mg-bt-4' : ''
									}`}
								>
									<span className='txt-color-black mg-r-10 '>{item.icon}</span>
									<span
										className={`txt-color-${
											activeKey.includes(item.key) ? 'black' : 'gray8'
										} fsz-14 line-h-22 robotolight`}
									>
										{item.label}
									</span>
								</div>
								<div className='flex flex-col mg-l-18 mg-r-0'>
									{item.dropdownItems.map((item: any, idx: number) => (
										<div
											key={idx}
											className={`flex align-items-center px-20 py-10 rad-16 mg-bt-6 cursor-pointer  ${
												activeKey.includes(item.key)
													? 'bg-color-orange2'
													: 'bg-color-white  menu-hovered '
											} icon ${idx === 0 ? 'mg-bt-6' : ''}`}
											onMouseEnter={() => {
												if (item.items?.length > 0) {
													setCurrentChildrenDrawer(item)
													handleOpenNestedDrawer(true)
												} else {
													setCurrentChildrenDrawer(null)
													handleOpenNestedDrawer(false)
												}
											}}
											onMouseLeave={() => {
												if (currentChildrenDrawer?.key !== item?.key) {
													handleOpenNestedDrawer(false)
													setCurrentChildrenDrawer(null)
												}
											}}
										>
											<span className='txt-color-black mg-r-10 '>{item.icon}</span>
											<span
												className={`txt-color-${
													activeKey === item.key ? 'black' : 'gray8'
												} fsz-14 line-h-22 robotolight`}
											>
												{item.label}
											</span>
										</div>
									))}
								</div>
							</div>
						) : (
							<Link
								to={`/${item.key}`}
								key={item.key}
								className={`flex align-items-center px-20 py-10 rad-16 mg-bt-6  ${
									activeKey === item.key ? 'bg-color-orange2' : 'bg-color-white menu-hovered '
								} icon`}
							>
								<span className='txt-color-black mg-r-10 flex align-items-center'>{item.icon}</span>
								<span
									className={`txt-color-${
										activeKey === item.key ? 'black3' : 'gray8'
									} fsz-14 line-h-22 mg-t-2 roboto${activeKey === item.key ? 'medium' : 'regular'}`}
								>
									{item.label}
								</span>
							</Link>
						)
					})}
				</div>
			</Drawer>
			<Menu items={menu} mode='vertical' theme='light' activeKey={activeKey} />
		</Sider>
	)
}

export default Sidebar
